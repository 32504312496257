export default {
  search_query: "Search Query",
  selected: "Selected",
  general: "General",
  manage_organisations: "Manage organisations",
  reference: "Reference",
  phone: "Phone",
  address: "Address",
  new: "New",
  yes: "Yes",
  no: "No",
  delete_permenent: "Are you sure you want to permanently delete this?",
  something_went_wrong:
    "Something went wrong please check your data and try again",
  from: "From",
  to: "To",
  company: "Company",
  individual: "Individual",

  submit: "Submit",
  welcome_back: "Welcome Back",
  welcome_desc: `Welcome to Gym Pro, an all-in-one gym management system with everything you need`,
  input_your: "Please input your {{field}}",
  settings: "Settings",
  choosePlan: "Choose Plan",
  week: "Week",
  month: "Month",
  day: "Day",
  today: "Today",
  how_its_done: "How It's done",
  active_members: "Active Members",
  new_members: "New Members",
  reports: "Reports",
  total_sales: "Total Sales",
  members_by_gender: "Members By Gender",
  revenue: "Revenue",
  todays_classes: "Today's Classes",
  no_classes_today: "No classes today",
  activity: "Activity",
  see_all: "see all",
  dashboard: "Dashboard",
  pending_validation: "Pending Validation",
  pending: "Pending",
  valid: "Valid",
  blocked: "Blocked",
  published: "Published"
};
