import { AddResponse, GetListResponse, User } from "../../global-types";
import axiosInstance, { apiRoutes } from "../config";
import { useTokenHook } from "../global-hooks";
import { MenuItem } from "../menuItem/menuItem.srv";

export interface Review {
  _id: string;
  status: "PENDING" | "BLOCKED" | "PUBLISHED";
  pictures?: string[];
  serviceRating: number;
  gastronomyRating: number;
  ambienceRating: number;
  comment: string;
  postTime: Date;
  menuId: string | MenuItem;
  userId: string | User;
  approvedBy: string | User;
}

export const reviewService = () => {
  const tokenHook = useTokenHook();

  return {
    ready: tokenHook.token?.length ? true : false,
    getReviews: (
      page: number,
      limit: number,
      restaurantId?: string
    ): Promise<GetListResponse<Review>> => {
      return new Promise((resolve) => {
        const req =
          restaurantId && restaurantId !== ""
            ? axiosInstance.get(
                `${apiRoutes.reviews}/by-restaurant/${restaurantId}?page=${page}&limit=${limit}`
              )
            : axiosInstance.get(
                `${apiRoutes.reviews}?page=${page}&limit=${limit}`
              );

        req
          .then((response) => {
            resolve({
              success: true,
              data: Array.isArray(response.data)
                ? response.data
                : response.data?.reviews || [],
              countAll: response.data?.total,
            });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    addReview: (data: Review): Promise<AddResponse<Review>> => {
      return new Promise((resolve) => {
        axiosInstance
          .post(`${apiRoutes.reviews}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    editReview: (_id: string, data: Review): Promise<AddResponse<Review>> => {
      return new Promise((resolve) => {
        axiosInstance
          .patch(`${apiRoutes.reviews}/${_id}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    deleteReview: (_id: string): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .delete(`${apiRoutes.reviews}/${_id}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
  };
};
