export default {
  logo: "Logo",
  name: "Nom",
  phone: "Téléphone",
  ref: "Référence",
  address: "Adresse",
  type: "Type",
  company_registry: "Registre",
  tax_id: "Tax ID",
  custom_code: "Code personnalisé",
  symbol: "Symbole",
  volume: "Volume",
  main: "Principal",
  rate: "Taux",
  last_update: "Dernière mise à jour",
  value: "Valeur",
  start: "Début",
  end: "Fin",
  firstName: "Prénom",
  lastName: "Nom",
  customs_code: "Customs code",
  tax_id_number: "Tax Id Number",
  bank_account: "Bank account",
  rib: "RIB",
  iban: "IBAN",
  street: "Rue",
  country: "Pays",
  city: "Ville",
  state: "État",
  zip: "Code postal",
  national_id: "CIN",
  passport_id: "Passeport",
  email: "Email",
  password: "Mot de passe",
  parent: "Parent",
  reference: "Référence",
  time: "Temps",
  direction: "Direction",
  accessPoint: "Point d'accès",
  user: "Utilisateur",
  status: "Statut",
  expiration: "Expiration",
  membership_expiration: "Expiration de Membership",
  contractStart: "Début du contrat",
  contractEnd: "Fin du contrat",
  category: "Catégorie",
  cardId: "CIN",
  birthday: "Anniversaire",
  gender: "Genre",
  startDate: "Date de début",
  advancePayment: "Acompte",
  paymentMethod: "Mode de paiement",
  discount: "Remise",
  signupFee: "Frais d'inscription",
};
