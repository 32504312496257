import axios from "axios";

const root = process.env.REACT_APP_API;
let axiosInstance = axios.create({
  baseURL: root,
});

export const MAP_KEY = process.env.REACT_APP_MAP_KEY || "";
export const MINIO_SERVER = process.env.REACT_APP_MINIO_SERVER || "";
export const MINIO_ACCESS_KEY = process.env.REACT_APP_MINIO_SERVER || "";
export const MINIO_SECRET_KEY = process.env.REACT_APP_MINIO_SERVER || "";

export const apiRoutes = {
  root,

  // AUTH
  auth: `${root}/auth`,
  login: `${root}/auth/login`,

  // USERS
  users: `${root}/users`,
  profile: `${root}/users/profile`,

  // RESTAURANTS
  restaurants: `${root}/restaurants`,
  filterRestaurants: `${root}/restaurants/filter`,

  // MENU
  menuItem: `${root}/menus`,
  menuTag: `${root}/tags`,

  // REVIEW
  reviews: `${root}/reviews`,
};
export const initAxios = (token: string) => {
  axiosInstance.interceptors.request.use((config) => {
    config.headers["Authorization"] = token;

    return config;
  });

  // *** REPLACED BY WATCH TOKEN EXPIRATION TIME ***

  // axiosInstance.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     if (error.response.status === 401) {
  //       // redirect the user to the login page or any other page as needed
  //       window.location.href = "/";
  //     }

  //     return Promise.reject(error);
  //   }
  // );
};

export const resetAxios = () => {
  axiosInstance = axios.create();
};

export default axiosInstance;
