import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Upload,
  UploadProps,
} from "antd";
import * as Minio from "minio";
import {
  MenuItemTag,
  menuTagService,
} from "../../../services/menuTag/menuTag.srv";
import { Row as DataTableRow } from "../../Listing/TypedDatatable";
import { useTranslation } from "react-i18next";
import {
  ExclamationCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import {
  MINIO_ACCESS_KEY,
  MINIO_SECRET_KEY,
  MINIO_SERVER,
} from "../../../services/config";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ManageMenuItemTagModel = (props: {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;

  action:
    | { type: "ADD"; tagType: "MENU" | "RESTAURANT" }
    | {
        type: "EDIT";
        tagType: "MENU" | "RESTAURANT";
        menuItemTag: MenuItemTag | DataTableRow<MenuItemTag>;
      };
}) => {
  // CONSTs
  const form: FormInstance = Form.useForm()[0];
  const { t } = useTranslation();

  // PROPS
  const { open, onCancel, onOk, action } = props;

  // SRVs
  const tagSrv = menuTagService();

  // STATES
  const [showAlert, setShowAlert] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  if (action.type === "EDIT" && !action.menuItemTag) return null;

  const handleChangeImage: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoadingUpload(false);
        setImageUrl(url);
      });
    }
  };

  const customUpload = async ({ file, onSuccess, onError }: any) => {
    console.log({ file, onSuccess, onError });
    console.log("file.path", file.path);

    try {
      const minioClient = new Minio.Client({
        endPoint: MINIO_SERVER,
        port: 443,
        useSSL: false,
        accessKey: MINIO_ACCESS_KEY,
        secretKey: MINIO_SECRET_KEY,
      });

      minioClient.putObject(
        "menupro",
        file.name,
        file,
        // { "Content-Type": file.type },
        (err, etag) => {
          if (err) {
            console.error("Error uploading file:", err);
          } else {
            console.log("File uploaded successfully:", etag);
          }
        }
      );

      setImageUrl("url.href");
      form.setFieldValue("picture", "url.href");

      onSuccess("url.href");
    } catch (error) {
      console.log("ERROR UPLOAD FILE", error);
      onError(error);
    }

    // const storageRef = ref(
    //   storage,
    //   `/uploads${`/${scope?.user?.salleId}` || ""}/admin/event/${nanoid(8)}_${
    //     file.name
    //   }`
    // );
    // try {
    //   await uploadBytes(storageRef, file);
    //   const downloadURL = await getDownloadURL(storageRef);
    //   let url = new URL(downloadURL);
    //   url.host = "cdn.gympro.tech";
    //   form.setFieldValue("image", url.href);
    //   setSelectedImage(url.href);
    //   onSuccess(url.href);
    // } catch (error) {
    //   onError(error);
    // }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: t("general.delete_permenent"),
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <strong>
            {action.type === "EDIT" ? action.menuItemTag?.name : ""}
          </strong>
        </>
      ),
      onOk() {
        return new Promise(async (resolve) => {
          const resp = await tagSrv.deleteTag(
            action.type === "EDIT" ? String(action.menuItemTag._id) : ""
          );
          if (resp.success) onOk();
          resolve(null);
        }).catch((err) => console.log("Err delete", err));
      },
    });
  };

  return (
    <>
      <Modal
        open={open}
        title={
          <>
            {action.type === "ADD" ? "Add" : "Edit"}{" "}
            {action.tagType === "MENU" ? "Menu" : t("model.restaurant.name")}{" "}
            Tag
          </>
        }
        onCancel={onCancel}
        footer={[
          <Row>
            <Col flex={"auto"} style={{ textAlign: "left" }}>
              <Button danger onClick={showDeleteConfirm}>
                {t("actions.delete")}
              </Button>
            </Col>
            <Col flex={"200px"}>
              <Button onClick={onCancel}>{t("actions.discard")}</Button>
              <Button
                type="primary"
                loading={loadingSubmit}
                onClick={() => form.submit()}
              >
                {t("actions.save")}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          className="add-tag-mdl"
          layout="vertical"
          onFinish={async (values) => {
            setLoadingSubmit(true);
            const resp =
              action.type === "ADD"
                ? await tagSrv.addMenuTag({ ...values, type: action.tagType })
                : await tagSrv.editMenuItem(
                    String(action.menuItemTag._id) || "",
                    { ...values, type: action.tagType }
                  );

            if (resp.success) onOk();
            else setShowAlert(true);

            setLoadingSubmit(false);
          }}
          // onValuesChange={(changes, allValues) => {
          //   console.log({ changes, allValues });
          // }}
          form={form}
          style={{ width: "100%" }}
          initialValues={
            action.type === "EDIT"
              ? { ...action.menuItemTag }
              : {
                  name: "",
                  type: action.tagType,
                  picture: undefined,
                }
          }
        >
          {showAlert && (
            <Alert
              message="Error"
              description="Something went wrong, please check your data and try again."
              onClose={() => setShowAlert(false)}
              type="error"
              closable
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            />
          )}
          <Row gutter={10}>
            <Col flex="100px">
              <Form.Item name="picture" label="Image">
                <Upload
                  name="picture"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={customUpload}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  // beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
                  style={{ minHeight: 120 }}
                  accept="image/*"
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div>
                      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManageMenuItemTagModel;
