import React, { useState } from "react";
import { FaList } from "react-icons/fa";
import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Restaurant } from "../../../services/restaurant/restaurant.srv";
import ManageRestaurant from "../../../components/restaurant/ManageRestaurant/ManageRestaurant";

const EditRestaurantPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  return (
    <MainLayoutContent
      navigations={[
        {
          label: t("model.restaurant.plural"),
          link: "/restaurants",
          icon: <FaList />,
          isMain: true,
        },
        {
          label: restaurant?.name || "",
          link: `/edit/${restaurant?._id}"`,
        },
      ]}
    >
      <ManageRestaurant
        action={{
          type: "EDIT",
          restaurantId: params.restaurantId || "",
          onRestaurantDetails: (restaurant) => setRestaurant(restaurant),
        }}
        onSave={(newResto) => {
          navigate(`/restaurants/view/${newResto?._id}`);
        }}
      />
    </MainLayoutContent>
  );
};

export default EditRestaurantPage;
