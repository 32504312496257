import React, { useState } from "react";

import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { FaTags } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ListMenuTags from "../../../components/menuTag/ListMenuTags/ListMenuTags";
import { MenuItemTag } from "../../../services/menuTag/menuTag.srv";
import ManageMenuItemTagModel from "../../../components/menuTag/ManageMenuItemTagModel/ManageMenuItemTagModel";
import { Row } from "../../../components/Listing/TypedDatatable";
import { Button, Card, Tabs } from "antd";

const TagsListPage = () => {
  const { t } = useTranslation();

  // STATES
  const [refresh, setRefresh] = useState<string>("");
  const [tagModelAction, setTagModelAction] = useState<
    | { type: "ADD"; tagType: "MENU" | "RESTAURANT" }
    | {
        type: "EDIT";
        tagType: "MENU" | "RESTAURANT";
        menuItemTag: MenuItemTag | Row<MenuItemTag>;
      }
    | null
  >(null);

  return (
    <div className="dashboard-page">
      <MainLayoutContent
        navigations={[
          {
            label: t("model.tag.plural"),
            link: "/",
            icon: <FaTags />,
            isMain: true,
          },
        ]}
      >
        <Tabs
          style={{ marginTop: 15, padding: 0 }}
          type="card"
          items={[
            {
              label: `Menu Tags`,
              key: "menu-tags-tab",
              children: (
                <Card bodyStyle={{ padding: 10 }}>
                  <div style={{ textAlign: "right", marginBottom: 10 }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        setTagModelAction({ type: "ADD", tagType: "MENU" });
                      }}
                    >
                      {`${t(`actions.add`)} ${t(`general.new`)}`}{" "}
                    </Button>
                  </div>

                  <ListMenuTags
                    tagType="MENU"
                    refresh={refresh}
                    handleRecordClick={(item) => {
                      setTagModelAction({
                        type: "EDIT",
                        tagType: "MENU",
                        menuItemTag: item,
                      });
                    }}
                  />
                </Card>
              ),
            },
            {
              label: `${t("model.restaurant.name")} Tags`,
              key: "resto-tags-tab",
              children: (
                <>
                  <Card bodyStyle={{ padding: 10 }}>
                    <div style={{ textAlign: "right", marginBottom: 10 }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          setTagModelAction({
                            type: "ADD",
                            tagType: "RESTAURANT",
                          });
                        }}
                      >
                        {`${t(`actions.add`)} ${t(`general.new`)}`}{" "}
                      </Button>
                    </div>

                    <ListMenuTags
                      tagType="RESTAURANT"
                      refresh={refresh}
                      handleRecordClick={(item) => {
                        setTagModelAction({
                          type: "EDIT",
                          tagType: "RESTAURANT",
                          menuItemTag: item,
                        });
                      }}
                    />
                  </Card>
                </>
              ),
            },
          ]}
        />

        {tagModelAction && (
          <ManageMenuItemTagModel
            open={true}
            action={tagModelAction}
            onOk={() => {
              setRefresh(Math.random().toString());
              setTagModelAction(null);
            }}
            onCancel={() => {
              setTagModelAction(null);
            }}
          />
        )}
      </MainLayoutContent>
    </div>
  );
};

export default TagsListPage;
