export default {
  add: "Ajouter",
  edit: "Modifier",
  delete: "Supprimer",
  Update: "Mise à jour",
  create: "Créer",
  import: "Importer",
  export: "Exporter",
  save: "Sauvegarder",
  discard: "Annuler",
  sort: "Trier",
  filter: "Filtre",
  notes: "Notes de ",
};
