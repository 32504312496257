export default {
  logo: "Logo",
  name: "Name",
  phone: "Phone",
  ref: "Reference",
  address: "Address",
  type: "Type",
  company_registry: "Company Registry",
  tax_id: "Tax ID",
  custom_code: "Custom Code",
  symbol: "Symbol",
  volume: "Volume",
  main: "Main",
  rate: "Rate",
  last_update: "Last update",
  value: "Value",
  start: "Start",
  end: "End",
  firstName: "First name",
  lastName: "Last name",
  customs_code: "Customs code",
  tax_id_number: "Tax Id Number",
  bank_account: "Compte bancaire",
  rib: "RIB",
  iban: "IBAN",
  street: "Street",
  country: "Country",
  city: "City",
  state: "State",
  zip: "Zip Code",
  national_id: "National ID",
  passport_id: "Passport ID",
  email: "Email",
  password: "Password",
  parent: "Parent",
  reference: "Reference",
  time: "Time",
  direction: "Direction",
  accessPoint: "Access Point",
  user: "User",
  status: "Status",
  expiration: "Expiration",
  membership_expiration: "Membership Expiration",
  contractStart: "Contract Start",
  contractEnd: "Contract End",
  category: "Category",
  cardId: "ID Card Number",
  birthday: "Birthday",
  gender: "gender",
  startDate: "Start Date",
  advancePayment: "Advance Payment",
  paymentMethod: "Payment Method",
  discount: "Discount",
  signupFee: "Signup fee",
};
