import React, { useState, useEffect, useRef } from "react";

/// <reference types="@types/googlemaps" />

interface MapProps {
  apiKey: string;
  onMarkerChanged?: (
    result: google.maps.GeocoderResult,
    position: { lat: number; lng: number }
  ) => void;
  initPosition: { lat: number; lng: number };
  zoom?: number;
}

const Map: React.FC<MapProps> = ({
  apiKey,
  onMarkerChanged,
  initPosition,
  zoom = 10,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [_map, setMap] = useState<google.maps.Map | null>(null);
  const [_marker, setMarker] = useState<google.maps.Marker | null>(null);

  // console.log("===> initPosition", initPosition);

  useEffect(() => {
    // Load the Google Maps script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.addEventListener("load", initializeMap);

    return () => {
      script.removeEventListener("load", initializeMap);
    };
  }, [apiKey]);

  const initializeMap = () => {
    const googleMap = new google.maps.Map(mapRef.current!, {
      center: initPosition,
      zoom,
    });

    const googleMarker = new google.maps.Marker({
      position: initPosition,
      map: googleMap,
      draggable: true,
    });

    googleMap.addListener("click", (event) => {
      const clickedLatLng = event.latLng;
      if (clickedLatLng) {
        googleMarker.setPosition(clickedLatLng);
        getAddressFromLatLng(clickedLatLng, (result) => {
          //   onMarkerDragEnd(lat, lng, address);
          if (onMarkerChanged && result) {
            onMarkerChanged(result, {
              lat: clickedLatLng.lat(),
              lng: clickedLatLng.lng(),
            });
          }
        });
      }
    });

    googleMarker.addListener("dragend", () => {
      const position = googleMarker.getPosition();
      if (position) {
        getAddressFromLatLng(position, (result) => {
          if (onMarkerChanged && result) {
            onMarkerChanged(result, {
              lat: position.lat(),
              lng: position.lng(),
            });
          }
        });
      }
    });

    setMap(googleMap);
    setMarker(googleMarker);
  };

  const getAddressFromLatLng = (
    latLng: google.maps.LatLng,
    callback: (result: google.maps.GeocoderResult | null) => void
  ) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        // const address = results[0].formatted_address;
        callback(results[0]);
      } else {
        callback(null);
      }
    });
  };

  return <div ref={mapRef} style={{ height: "400px" }} />;
};

export default Map;
