import actions from "./fr-actions";
import general from "./fr-general";
import fields from "./fr-fields";
import restaurant from "./models/restaurant";
import tag from "./models/tag";
import review from "./models/review";
import user from "./models/user";

export default {
  actions,
  general,
  fields,
  model: {
    restaurant,
    tag,
    review,
    user
  },
};
