import { Popconfirm, Space, Spin, TablePaginationConfig } from "antd";
// import type { MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import ListingTable from "../../Listing/ListingTable";
import { DataColumn, Row } from "../../Listing/TypedDatatable";
// import { FaPencilAlt, FaTrash } from "react-icons/fa";
// import { durationToMonthes } from "../../../ultils/utils";
import {
  Restaurant,
  restaurantService,
} from "../../../services/restaurant/restaurant.srv";
import { FaCheckSquare, FaTrash, FaWindowClose } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface CustomRestaurant extends Restaurant {
  actions?: JSX.Element; //MenuProps["items"];
}
const RestaurantsList = (props: {
  refresh: string;
  searchQuery?: string;
  onClickEdit?: (item: Restaurant) => void;
  onClickDelete?: (item: Restaurant) => void;
  handleRecordClick?: (item: Restaurant) => void;
}) => {
  const { t } = useTranslation();
  const columns: DataColumn<CustomRestaurant> = {
    logo: {
      title: "Logo",
      width: 60,
      type: "image",
      key: "logo",
    },
    title: {
      title: "Name",
      // sortable: false,
      type: "text",
      key: "title",
    },
    // slogan: {
    //   title: "Slogan",
    //   sortable: false,
    //   type: "text",
    //   key: "slogan",
    // },
    address: {
      title: "Address",
      sortable: false,
      type: "text",
      key: "address",
    },
    // suggested_by: {
    //   title: "Suggested By",
    //   type: "text",
    //   key: "suggested_by",
    // },
    tags: {
      title: "Tags",
      // sortable: false,
      type: "menutags",
      key: "tags",
    },
    status: {
      title: "Status",
      // sortable: false,
      type: "status",
      key: "status",
    },
    actions: {
      title: " ",
      width: 100,
      clickable: true,
      type: "text",
      key: "actions",
    },
  };

  const { refresh, handleRecordClick, searchQuery } = props;

  // STATES
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<CustomRestaurant[]>([]);
  const [filters, setFilters] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 20,
  });
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [loadingRestaurant, setLoadingRestaurant] = useState<boolean>(false);

  // SRVs
  const restoSrv = restaurantService();

  const pagination: TablePaginationConfig = {
    total,
    pageSize,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal(total, _range) {
      return `total ${total}`;
    },
    position: ["bottomRight"],
    onChange(page, pageSize) {
      // console.log({ page, pageSize });
      setPageSize(pageSize);

      setFilters({ page, limit: pageSize });
    },
  };

  useEffect(() => {
    if (restoSrv.ready) getPage(filters.page, filters.limit, searchQuery);
  }, [filters, restoSrv.ready, refresh, searchQuery]);

  const changeRestaurantStatus = async (
    restaurant: Restaurant,
    status: "PENDING" | "BLOCKED" | "PUBLISHED"
  ) => {
    setLoadingRestaurant(true);
    const resp = await restoSrv.editRestaurant(restaurant._id, {
      ...restaurant,
      tags: restaurant.tags.map((tag) => {
        if (typeof tag === "string") return tag;
        return tag?._id;
      }),
      status,
    });
    setLoadingRestaurant(false);
    if (resp.success) getPage(filters.page, filters.limit);
  };

  const getPage = async (page: number, limit: number, searchQuery?: string) => {
    setLoading(true);
    // const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);
    const resp = await restoSrv.getRestaurants(page, limit, searchQuery);

    if (resp.success) {
      setRows(
        resp.data.map((item: Restaurant) => {
          return {
            ...item,
            title: (
              <>
                <p style={{ margin: 0, fontWeight: 500 }}>{item.name || ""}</p>
                <span style={{ color: "#777" }}>{item.slogan || ""}</span>
              </>
            ),
            actions: loadingRestaurant ? (
              <Spin />
            ) : (
              <Space size={10} className="review-actions">
                <FaCheckSquare
                  className={`review-actions-check ${
                    item.status === "PUBLISHED" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeRestaurantStatus(item, "PUBLISHED");
                  }}
                />
                <FaWindowClose
                  className={`review-actions-close ${
                    item.status === "BLOCKED" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeRestaurantStatus(item, "BLOCKED");
                  }}
                />

                <Popconfirm
                  title={t("general.delete_permenent")}
                  description={item.name}
                  onConfirm={async () => {
                    setLoadingRestaurant(true);
                    const resp = await restoSrv.deleteRestaurant(item._id);
                    setLoadingRestaurant(false);
                    if (resp.success) getPage(filters.page, filters.limit);
                  }}
                  okText={t("general.yes")}
                  cancelText={t("general.no")}
                >
                  {/* <Button danger style={{ marginLeft: 10 }}>
                    Delete
                  </Button> */}
                  <FaTrash
                    className="review-actions-trash"
                    style={{ marginLeft: 15 }}
                  />
                </Popconfirm>
              </Space>
            ),
          };
        })
      );

      setTotal(resp.countAll);
    }
    setLoading(false);
  };

  return (
    <ListingTable
      data={{
        rows: rows as Row<Restaurant>[],
        columns,
      }}
      onSelect={() => {}}
      size={"middle"}
      pagination={pagination}
      loading={loading}
      onRowClick={(_e, record) => {
        if (handleRecordClick) handleRecordClick(record as Restaurant);
      }}
    />
  );
};

export default RestaurantsList;
