export default {
  search_query: "Chercher par",
  selected: "Sélectionné",
  general: "Général",
  manage_organisations: "Gérer les organisations",
  reference: "Référence",
  phone: "Phone",
  address: "Adresse",
  new: "Nouveau",
  yes: "Oui",
  no: "Non",
  delete_permenent: "Êtes-vous sûr de vouloir le supprimer définitivement ?",
  something_went_wrong:
    "Something went wrong please check your data and try again",
  from: "De",
  to: "À",
  company: "Entreprise",
  individual: "Individuel",

  submit: "Soumettre",
  welcome_back: "Bienvenue",
  welcome_desc: `Bienvenue dans Gym Pro, un système de gestion de salle de sport tout-en-un avec 
  tout ce dont vous avez besoin`,
  input_your: "Veuillez saisir votre {{field}}",
  settings: "Paramètres",
  choosePlan: "Choisissez un forfait",
  week: "Semaine",
  month: "Mois",
  day: "Jour",
  today: "Aujourd'hui",
  how_its_done: "Comment c'est fait",
  active_members: "Membres actifs",
  new_members: "Nouveaux membres",
  reports: "Rapports",
  total_sales: "Totales Ventes",
  members_by_gender: "Membres par genre",
  revenue: "Revenu",
  todays_classes: "Les cours d'aujourd'hui",
  no_classes_today: "Pas de cours aujourd'hui",
  activity: "Activité",
  see_all: "voir tout",
  dashboard: "Tableau de bord",
  pending_validation: "Validation en attente",
  pending: "En attente",
  valid: "Validé",
  blocked: "Bloqué",
  published: "Publié",
};
