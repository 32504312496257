import { atom } from "jotai";
import { User } from "../global-types";
const SCOPE_KEY = "SCOPE";
const EVENT_VIEW_KEY = "EVENT_VIEW";
const LANG_KEY = "LANG";

export interface ScopeModel {
  connected: boolean;
  token: string;
  accessToken: string;
  exp: number;
  user: User | undefined;
}

export interface EventViewModel {
  eventView: "LIST" | "GRID";
}

export interface LangModel {
  lang: "en_US" | "fr_FR" | "ar_AR";
}

const atomWithLocalStorage = <T>(
  key: string,
  init_value:
    | {
        type: "SCOPE";
        data: ScopeModel;
      }
    | {
        type: "EVENT_VIEW";
        data: EventViewModel;
      }
    | {
        type: "LANGUAGE";
        data: LangModel;
      }
    | object
) => {
  const getInitialValue = () => {
    try {
      const item = localStorage.getItem(key);

      // const item = await asyncLocalStorage.getItem(key);
      // let item: string | null = null;

      // asyncLocalStorage.getItem(key).then((resp) => {
      //   if (!resp) return init_value;
      //   return JSON.parse(resp);
      // });

      if (!item) return init_value;
      return JSON.parse(item);
    } catch (error) {
      console.log(`Error parse default atom value (${key})`, error);

      return init_value;
    }
  };

  const baseAtom = atom(getInitialValue());

  const derivedAtom = atom<T, T>(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue =
        typeof update === "function" ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};

export const scope_atom = atomWithLocalStorage<ScopeModel>(SCOPE_KEY, {
  type: "SCOPE",
  data: {
    connected: false,
    token: "",
    user: undefined,
  },
});

export const lang_atom = atomWithLocalStorage<LangModel>(LANG_KEY, {
  type: "LANGUAGE",
  data: { lang: navigator.language || "en_US" },
});

export const eventViewAtom = atomWithLocalStorage<EventViewModel>(
  EVENT_VIEW_KEY,
  {
    type: "EVENT_VIEW",
    data: {
      eventView: "LIST",
    },
  }
);
