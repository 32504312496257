import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Button, Col, Row, Segmented, Space } from "antd";
import React from "react";
import { FaFilter, FaSortAmountUp } from "react-icons/fa";

import "./actions-bar.scss";
import { useTranslation } from "react-i18next";
import SearchInput from "./SearchInput/SearchInput";

const ActionsBar = (props: {
  hideSearch?: boolean;
  hideFilters?: boolean;
  rightActions?: {
    type?: "ICON" | "BUTTON";
    selectedView?: "LIST" | "GRID";
    onChange?: (value: "LIST" | "GRID") => void;
    text: string;
    classes?: string;
    onClick: () => void;
  }[];
  onSearch?: (query: string) => void;
}) => {
  const { t } = useTranslation();

  const { rightActions, hideFilters, hideSearch, onSearch } = props;

  return (
    <Row className="actions-bar">
      <Col md={6} className="search-wrap">
        {/* {!hideSearch && (
          <Input
            prefix={<FaSearch style={{ color: "#999CA0" }} />}
            placeholder={t("general.search_query")}
            onChange={()}
          />
        )} */}
        {!hideSearch && <SearchInput onSearch={onSearch} />}
      </Col>
      <Col md={6}>
        {!hideFilters && (
          <Space size={25} className="filter-actions">
            <div className="filter-wrap">
              <FaSortAmountUp className="icon" />
              <span className="filter-text">{t("actions.sort")}</span>
            </div>
            <div className="filter-wrap">
              <FaFilter className="icon" />
              <span className="filter-text">{t("actions.filter")}</span>
            </div>
          </Space>
        )}
      </Col>
      <Col md={12} className="right-actions">
        {rightActions?.map((action, index) => {
          switch (action.type) {
            case "ICON":
              return (
                <Segmented
                  value={action.selectedView || "LIST"}
                  options={[
                    {
                      value: "LIST",
                      icon: <BarsOutlined />,
                    },
                    {
                      value: "GRID",
                      icon: <AppstoreOutlined />,
                    },
                  ]}
                  onChange={(value) => {
                    if (action.onChange)
                      action.onChange(value === "GRID" ? "GRID" : "LIST");
                  }}
                />
              );

            default:
              return (
                <Button
                  key={index}
                  onClick={action.onClick}
                  className={action.classes ? action.classes : "main-btn"}
                  type="primary"
                  style={{ marginLeft: 5 }}
                  size="large"
                >
                  {t(action.text)}
                </Button>
              );
          }
        })}
      </Col>
    </Row>
  );
};

export default ActionsBar;
