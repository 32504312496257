import { useAtom } from "jotai";
import {
  AddResponse,
  GetListResponse,
  GetRecordResponse,
  User,
} from "../global-types";
import { LangModel, lang_atom, scope_atom } from "../store";
import axiosInstance, { apiRoutes } from "./config";
import { useTokenHook } from "./global-hooks";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const userService = () => {
  const tokenHook = useTokenHook();
  const [scope, setScope] = useAtom(scope_atom);
  const [lang, setLanguage] = useAtom(lang_atom);

  const { i18n } = useTranslation();

  return {
    ready: tokenHook.token?.length ? true : false,
    login: (
      email: string,
      password: string
    ): Promise<GetRecordResponse<{ accessToken: string }>> => {
      return new Promise((resolve) => {
        axios
          .post(apiRoutes.login, { email, password })
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },

    getProfile: (token?: string): Promise<GetRecordResponse<User>> => {
      return new Promise((resolve) => {
        if (token)
          axios
            .get(apiRoutes.profile, { headers: { Authorization: token } })
            .then((response) =>
              resolve({ success: true, record: response.data })
            )
            .catch((error) => resolve({ success: false, error }));
        else
          axiosInstance
            .get(apiRoutes.users)
            .then((response) => resolve(response.data))
            .catch((error) => resolve({ success: false, error }));
      });
    },
    getUsers: (
      offset: number,
      limit: number
    ): Promise<GetListResponse<User>> => {
      return new Promise((resolve) => {
        axiosInstance
          .get(`${apiRoutes.users}?offset=${offset}&limit=${limit}`)
          .then((response) => {
            console.log("USERS", response.data);

            resolve({
              success: true,
              data: response.data,
              countAll: response.data.countAll || response.data?.length,
            });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },

    deleteUser: (id: string): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .delete(`${apiRoutes.users}/${id}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },

    changePassword: (
      userId: string,
      newPassword: string
    ): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .post(`${apiRoutes.users}/change-password`, { userId, newPassword })
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },

    switchLang: () =>
      // VALUE
      {
        // FORM MORE THAN 2 LANGs, VALUE MUST BE PASSES AS PARAM.
        const value = lang.lang === "en_US" ? "fr_FR" : "en_US";

        // const reload =
        //   (value === "ar_EG" && i18n.language != "ar_EG") ||
        //   (value !== "ar_EG" && i18n.language === "ar_EG");

        setLanguage({ lang: value } as LangModel);
        i18n.changeLanguage(value);
        // if (reload) window?.location?.reload();
      },
    logOut: (redirect = true) => {
      setScope({
        ...scope,
        accessToken: "",
        token: "",
        connected: false,
      });

      // RESET AXIOS NOT WORKING UNLESS REFRESH PAGE
      // if (scope.connected || scope.token || scope.token !== "") {
      //   console.log("RESETING");
      //   axiosInstance.interceptors.request.use((config) => {
      //     delete config.headers["Authorization"];

      //     return config;
      //   });
      //   axiosInstance.defaults.headers.common["Authorization"] = null; // remove token from headers
      //   axiosInstance.interceptors.request.eject(1); // remove request interceptor
      //   axiosInstance.interceptors.response.eject(2); // remove response interceptor

      //   delete axiosInstance.defaults.headers.common["Authorization"];

      //   // const newApi = axios.create({
      //   //   baseURL: 'https://example.com',
      //   // });
      //   resetAxios();
      // }
      // console.log({ redirect });

      // TEMPORARY TO RESET AXIOS
      if (redirect) window.location.href = "/";
    },
  };
};
