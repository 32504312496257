import React, { useState } from "react";
import { FaList } from "react-icons/fa";
import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ViewRestaurant from "../../../components/restaurant/ViewRestaurant/ViewRestaurant";
import { Restaurant } from "../../../services/restaurant/restaurant.srv";

const ViewRestaurantPage = () => {
  const { t } = useTranslation();

  const params = useParams();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  
  return (
    <MainLayoutContent
      navigations={[
        {
          label: t("model.restaurant.plural"),
          link: "/restaurants",
          icon: <FaList />,
          isMain: true,
        },
        {
          label: restaurant?.name || "",
          link: `/view/${restaurant?._id}"`,
        },
      ]}
    >
      <ViewRestaurant
        restaurantId={params.restaurantId}
        onRestaurantDetails={(restaurant) => setRestaurant(restaurant)}
      />
    </MainLayoutContent>
  );
};

export default ViewRestaurantPage;
