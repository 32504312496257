import React, { Key } from "react";
import {
  Dropdown,
  Image,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import TypedDatatable, { IdData, Column, Row } from "./TypedDatatable";
import { useTranslation } from "react-i18next";
import { BsCheck2Circle } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";

import "./listing-table.scss";
import dayjs from "dayjs";
import { FaEllipsisV, FaPrint, FaSave, FaSms } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";

import { CalendarOutlined } from "@ant-design/icons";
import logoSrc from "../../assets/logo.svg";
import { MenuItemTag } from "../../services/menuTag/menuTag.srv";

const ListingTable: <T extends IdData>(props: {
  data: TypedDatatable<T>;
  pagination: TablePaginationConfig | false;
  loading: boolean;
  onSelect?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
  selection_mode?: "single" | "multi";
  size?: "small" | "middle" | "large";
  onRowClick?: (
    event: React.MouseEvent,
    record: Row<T>,
    rowIndex?: number
  ) => void;
  show_actions_bar?: boolean;
  isSettingsTable?: boolean;
}) => JSX.Element = (props) => {
  const {
    selection_mode,
    onSelect = () => {},
    onRowClick,
    size = "small",
    data,
    pagination,
    loading,
    show_actions_bar,
    isSettingsTable,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {show_actions_bar && (
        <div className="listing-head-actions">
          <GoLinkExternal className="action-icon" />
          <FaPrint className="action-icon" />
          <FaSave className="action-icon" />
          <IoMailOutline className="action-icon" />
          <FaSms className="action-icon" />
        </div>
      )}

      <Table
        className={`generic-listing-table ${
          isSettingsTable ? "settings-list" : ""
        }`}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (event) => {
        //       if (onRowClick) onRowClick(event, record, rowIndex);
        //     }, // click row
        //     // onDoubleClick: event => {}, // double click row
        //     // onContextMenu: event => {}, // right button click row
        //     // onMouseEnter: event => {}, // mouse enter row
        //     // onMouseLeave: event => {}, // mouse leave row
        //   };
        // }}
        rowClassName={`${onRowClick ? "clickable" : ""} ${
          isSettingsTable ? "table-row-spacing" : ""
        } `}
        bordered
        loading={loading}
        rowSelection={
          selection_mode
            ? {
                type: selection_mode === "single" ? "radio" : "checkbox",
                onChange: onSelect,
                getCheckboxProps: () =>
                  // record: any
                  {
                    // Get Checkbox or Radio props
                    return {
                      disabled: false,
                      // name: record?.name,
                    };
                  },
              }
            : undefined
        }
        rowKey={(record) => record._id}
        dataSource={data.rows}
        columns={Object.keys(data.columns).map((column_key) => {
          const item = data.columns[column_key]; //as Column
          return {
            title: (t(item?.title) as string) || "",
            dataIndex: column_key,
            key: column_key,
            align: item.align,
            width: item.width,
            onCell: (record: any) => {
              return {
                onClick: (event) => {
                  if (item.clickable) {
                    // TODO PASS ON CELL CLICK FN
                  } else {
                    if (onRowClick) {
                      // const target = event.target as HTMLElement;
                      // console.log("target.classList", target.classList);
                      onRowClick(event, record);
                    }
                  }
                },
              };
            },
            render: (text: any, record: any) => {
              return (item as Column).type === "image" ? (
                text && text !== "" ? (
                  <Image
                    // src={`https://${minio_configs.endPoint}/${minio_configs.bucket}/${text}`}
                    src={text}
                    width={item.width || "100%"}
                    height={item.height || "unset"}
                    fallback={logoSrc}
                  />
                ) : (
                  <Image
                    src={logoSrc}
                    width={item.width || "100%"}
                    height={item.height || "unset"}
                  />
                )
              ) : (item as Column).type === "boolean" ? (
                text ? (
                  <BsCheck2Circle style={{ fontSize: 18 }} color="green" />
                ) : (
                  <CgCloseO style={{ fontSize: 18 }} color="red" />
                )
              ) : (item as Column).type === "number" ? (
                text
              ) : (item as Column).type === "username" ? (
                <Space size={3}>
                  {/* <img
                    className="gender-img"
                    src={
                      record.gender?.toLowerCase() === "female" ? female : male
                    }
                    alt="player"
                  />{" "} */}
                  {text}
                </Space>
              ) : (item as Column).type === "text" ? (
                text
              ) : (item as Column).type === "menutags" ? (
                <Space size={5}>
                  {record[item?.key]?.map((tag: MenuItemTag, indx: number) => {
                    return (
                      <div
                        key={`menu-item-tag-${indx}-${tag._id}`}
                        className="menu-item-tag-wraper"
                      >
                        {typeof tag === "string" ? (
                          tag
                        ) : (
                          <Space size={5} align="center">
                            {tag.picture && tag.picture !== "" && (
                              <Image
                                onClick={(e) => e.stopPropagation()}
                                src={tag.picture}
                                width={40}
                                fallback={logoSrc}
                              />
                            )}
                            {tag.name}
                          </Space>
                        )}
                      </div>
                    );
                  })}
                </Space>
              ) : (item as Column).type === "stringtags" ? (
                `[${record[item?.key].split(",").join("; ")}]`
              ) : (item as Column).type === "datetime" ? (
                <>
                  <CalendarOutlined
                    style={{ color: "#999", fontSize: 14, paddingRight: 5 }}
                  />
                  {dayjs(text).format(item.format || "DD/MM/YYYY")}
                </>
              ) : (item as Column).type === "actions" ? (
                <>
                  <Dropdown
                    menu={{ items: record.actions || [] }}
                    trigger={["click", "hover"]}
                  >
                    <FaEllipsisV style={{ color: "#C5C7CD" }} />
                  </Dropdown>
                </>
              ) : (item as Column).type === "status" ? (
                <>
                  {text === "PUBLISHED" && (
                    <Tag color="success">
                      {t(`general.${String(text).toLocaleLowerCase()}`)}
                    </Tag>
                  )}
                  {(text === "PENDING" || text === "pending_validation") && (
                    <Tag color="warning">
                      {t(`general.${String(text).toLocaleLowerCase()}`)}
                    </Tag>
                  )}
                  {text === "BLOCKED" && (
                    <Tag color="#ff0019">
                      {t(`general.${String(text).toLocaleLowerCase()}`)}
                    </Tag>
                  )}
                </>
              ) : (
                // moment.utc(text).local().format("lll")
                text
              );
            },
          };
        })}
        size={size}
        pagination={pagination}
      />
    </>
  );
};

export default ListingTable;
