import { Layout, theme } from "antd";
import React from "react";
import TopMenu from "../../components/TopMenu/TopMenu";
import { NavigationModel } from "../../ultils/models/models";

// import "./main-layout.scss";

export const MainLayoutContent = (props: {
  children: any;
  navigations: NavigationModel[]
}) => {
  
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { children, navigations } = props;
  return (
    <>
      <Layout.Header style={{ padding: 0, background: colorBgContainer }}>
        <TopMenu navigations={navigations} />
      </Layout.Header>
      <div style={{ margin: "24px 16px 0" }}>
        <div
          className="main-layout-content"
          // style={{
          //   background: colorBgContainer,
          // }}
        >
          {children}
        </div>
      </div>
    </>
  );
};
