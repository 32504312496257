import { AddResponse, GetListResponse } from "../../global-types";
import axiosInstance, { apiRoutes } from "../config";
import { useTokenHook } from "../global-hooks";
import { MenuItemTag } from "../menuTag/menuTag.srv";

export interface MenuItem {
  _id: string;
  title: string;
  description?: string;
  picture?: string;
  price: number;
  menuTags: string[] | MenuItemTag[];
  restaurantId: string;
}
export const menuItemService = () => {
  const tokenHook = useTokenHook();

  return {
    ready: tokenHook.token?.length ? true : false,
    getMenuItems: (
      page: number,
      limit: number,
      restaurantId: string
    ): Promise<GetListResponse<MenuItem>> => {
      return new Promise((resolve) => {
        axiosInstance
          .get(
            `${apiRoutes.menuItem}/restaurant/${restaurantId}?page=${page}&limit=${limit}`
          )
          .then((response) => {
            resolve({
              success: true,
              data: response.data,
              countAll: response.data?.length,
            });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    addMenuItem: (data: MenuItem): Promise<AddResponse<MenuItem>> => {
      return new Promise((resolve) => {
        axiosInstance
          .post(`${apiRoutes.menuItem}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    editMenuItem: (
      _id: string,
      data: MenuItem
    ): Promise<AddResponse<MenuItem>> => {
      return new Promise((resolve) => {
        axiosInstance
          .patch(`${apiRoutes.menuItem}/${_id}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    deleteMenuItem: (_id: string): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .delete(`${apiRoutes.menuItem}/${_id}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
  };
};
