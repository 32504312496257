export default {
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  Update: "Update",
  create: "Create",
  import: "Import",
  export: "Export",
  save: "Save",
  discard: "Discard",
  sort: "Sort",
  filter: "Filter",
  notes: "Notes of ",
};
