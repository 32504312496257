import { TablePaginationConfig } from "antd";
import React, { useEffect, useState } from "react";
import ListingTable from "../../Listing/ListingTable";
import { DataColumn, Row } from "../../Listing/TypedDatatable";
import {
  MenuItemTag,
  menuTagService,
} from "../../../services/menuTag/menuTag.srv";

interface CustomMenuItemTag extends MenuItemTag {
  actions?: any[]; //MenuProps["items"];
}
const ListMenuTags = (props: {
  tagType: "MENU" | "RESTAURANT";
  refresh: string;
  onClickEdit?: (item: MenuItemTag) => void;
  onClickDelete?: (item: MenuItemTag) => void;
  handleRecordClick?: (item: MenuItemTag) => void;
}) => {
  const columns: DataColumn<MenuItemTag> = {
    picture: {
      title: "Image",
      width: 60,
      type: "image",
      key: "picture",
    },
    name: {
      title: "Name",
      sortable: false,
      type: "text",
      key: "name",
    },
    // actions: {
    //   title: " ",
    //   type: "actions",
    //   key: "actions",
    //   align: "right",
    //   width: 30,
    // },
  };

  const { refresh, handleRecordClick, tagType } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<CustomMenuItemTag[]>([]);
  const [filters, setFilters] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 20,
  });
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const tagSrv = menuTagService();

  const pagination: TablePaginationConfig = {
    total,
    pageSize,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal(total, _range) {
      return `total ${total}`;
    },
    position: ["bottomRight"],
    onChange(page, pageSize) {
      // console.log({ page, pageSize });
      setPageSize(pageSize);

      setFilters({ page, limit: pageSize });
    },
  };

  useEffect(() => {
    if (tagSrv.ready) getPage();
  }, [filters, tagSrv.ready, refresh]);

  const getPage = async () => {
    setLoading(true);
    // const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);
    const resp = await tagSrv.getMenuTags(tagType);

    if (resp.success) {
      setRows(
        resp.data.map((item: MenuItemTag) => {
          return {
            ...item,
            // actions: [
            //   {
            //     key: "edit",
            //     icon: <FaPencilAlt />,
            //     label: "Edit",
            //     onClick: () => {
            //       // e.stopPropagation();
            //       onClickEdit(item);
            //     },
            //   },
            //   {
            //     key: "delete",
            //     icon: <FaTrash />,
            //     danger: true,
            //     label: "Delete",
            //     onClick: () => {
            //       // e.stopPropagation();
            //       onClickDelete(item);
            //     },
            //   },
            // ],
          };
        })
      );

      setTotal(resp.countAll);
    }
    setLoading(false);
  };

  return (
    <ListingTable
      data={{
        rows: rows as Row<MenuItemTag>[],
        columns,
      }}
      onSelect={() => {}}
      size={"middle"}
      pagination={pagination}
      loading={loading}
      onRowClick={(_e, record) => {
        if (handleRecordClick) handleRecordClick(record as MenuItemTag);
      }}
    />
  );
};

export default ListMenuTags;
