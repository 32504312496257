import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { MainLayoutContent } from "../../layouts/MainLayout/MainLayoutContent";

const NotFoundPage = (props: { subTitle?: string; children?: any }) => {
  const { subTitle, children } = props;

  return (
    <MainLayoutContent navigations={[]}>
      <Result
        status="404"
        title="404"
        subTitle={subTitle || "Sorry, the page you visited does not exist."}
        extra={
          children ? (
            children
          ) : (
            <Link to="/">
              <Button type="default">Back Home</Button>
            </Link>
          )
        }
      />
    </MainLayoutContent>
  );
};

export default NotFoundPage;
