import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SearchInput = (props: { onSearch?: (query: string) => void }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>("");
  const { onSearch } = props;

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Perform your search logic here, e.g., make an API call
      if (onSearch) onSearch(searchValue);
    }, 300);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Input
      prefix={<FaSearch style={{ color: "#999CA0" }} />}
      placeholder={t("general.search_query")}
      onChange={(e) => handleSearch(e.target.value)}
      value={searchValue}
    />
  );
};

export default SearchInput;
