import React, { useState } from "react";
import { FaList } from "react-icons/fa";
import ActionsBar from "../../../components/Listing/ActionsBar/ActionsBar";

import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { useTranslation } from "react-i18next";
import RestaurantsList from "../../../components/restaurant/RestaurantsList/RestaurantsList";
import { useNavigate } from "react-router-dom";

const RestaurantsListPage = () => {
  // const restaurantSrv = restaurantService();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <MainLayoutContent
      navigations={[
        {
          label: "Restaurants",
          link: "/restaurants",
          icon: <FaList />,
          isMain: true,
        },
      ]}
    >
      <ActionsBar
        // hideSearch
        hideFilters
        rightActions={[
          {
            text: `${t("general.new")} ${t("model.restaurant.name")}`,
            onClick: () => {
              navigate("/restaurants/add")
            },
          },
        ]}
        onSearch={(query) => setSearchQuery(query)}
      />

      <RestaurantsList
        refresh={""}
        searchQuery={searchQuery}
        // onClickEdit={(item: Restaurant) => {
        //   setSelectedRestaurant(item);
        //   setOpenEditMdl(true);
        // }}
        // onClickDelete={(item: Restaurant) => {
        //   setSelectedRestaurant(item);
        //   setShowDeleteConfirm(true);
        // }}
        handleRecordClick={(record)=>{
          navigate(`/restaurants/view/${record._id}`);
        }}
      />
    </MainLayoutContent>
  );
};

export default RestaurantsListPage;
