import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import MainLayout from "../layouts/MainLayout/MainLayout";
import DashboardPage from "../pages/dashboard/Dashboard.page";
import NotFoundPage from "../components/404/404";
import { useAtom } from "jotai";
import { scope_atom } from "../store";
import RestaurantsListPage from "../pages/restaurants/list/RestaurantsListPage";
import AddRestaurantPage from "../pages/restaurants/add/AddRestaurantPage";
import ViewRestaurantPage from "../pages/restaurants/view/ViewRestaurantPage";
import TagsListPage from "../pages/tags/list/TagsListPage";
import ReviewsListPage from "../pages/reviews/list/ReviewsListPage";
import UsersListPage from "../pages/users/list/UsersListPage";
import EditRestaurantPage from "../pages/restaurants/edit/EditRestaurantPage";

const Router: React.FC = (): // props: { user?: object }
JSX.Element => {
  const routesByAuth = () => {
    // ********* TO DO : TEST INCOGNITO *********

    const [scope] = useAtom(scope_atom);

    switch (scope?.user?.role?.toUpperCase()) {
      case "USER":
      case "SUPER_ADMIN":
        return (
          <Route
            path=""
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path="" element={<DashboardPage />} />

            <Route path="restaurants">
              <Route index element={<RestaurantsListPage />} />
              <Route path="add" element={<AddRestaurantPage />} />
              <Route
                path="view/:restaurantId"
                element={<ViewRestaurantPage />}
              />
              <Route
                path="edit/:restaurantId"
                element={<EditRestaurantPage />}
              />
            </Route>

            <Route path="tags" element={<TagsListPage />} />
            <Route path="reviews" element={<ReviewsListPage />} />
            <Route path="users" element={<UsersListPage />} />
          </Route>
        );
      // case "MANAGER":
      // case "ADMIN":
      //   return (
      //     <Route
      //       path=""
      //       element={
      //         <MainLayout>
      //           <Outlet />
      //         </MainLayout>
      //       }
      //     >
      //       <Route path="" element={<DashboardPage />} />
      //     </Route>
      //   );
      default:
        return null;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<div>Loading..</div>} /> */}
        {routesByAuth()}
        <Route
          path="404"
          element={
            true ? (
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            ) : (
              <NotFoundPage />
            )
          }
        />

        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
