import { AddResponse, GetListResponse } from "../../global-types";
import axiosInstance, { apiRoutes } from "../config";
import { useTokenHook } from "../global-hooks";

export interface MenuItemTag {
  _id: string;
  name: string;
  type: "MENU" | "RESTAURANT";
  picture?: string;
}
export const menuTagService = () => {
  const tokenHook = useTokenHook();

  return {
    ready: tokenHook.token?.length ? true : false,
    getMenuTags: (
      tagType: "MENU" | "RESTAURANT"
    ): Promise<GetListResponse<MenuItemTag>> => {
      return new Promise((resolve) => {
        axiosInstance
          .get(`${apiRoutes.menuTag}/${tagType?.toLocaleLowerCase()}`)
          .then((response) => {
            resolve({
              success: true,
              data: response.data,
              countAll: response.data?.length,
            });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    addMenuTag: (data: MenuItemTag): Promise<AddResponse<MenuItemTag>> => {
      return new Promise((resolve) => {
        axiosInstance
          .post(`${apiRoutes.menuTag}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    editMenuItem: (
      _id: string,
      data: MenuItemTag
    ): Promise<AddResponse<MenuItemTag>> => {
      return new Promise((resolve) => {
        axiosInstance
          .put(`${apiRoutes.menuTag}/${_id}`, data)
          .then((response) => {
            resolve({ success: true, record: response.data });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    deleteTag: (id: string): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .delete(`${apiRoutes.menuTag}/${id}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
  };
};
