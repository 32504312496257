import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  TablePaginationConfig,
  message,
} from "antd";
import ListingTable from "../../Listing/ListingTable";
import {
  MenuItem,
  menuItemService,
} from "../../../services/menuItem/menuItem.srv";
import { DataColumn, Row as DataTableRow } from "../../Listing/TypedDatatable";
import { useTranslation } from "react-i18next";
import ManageMenuItemModel from "../../menuItem/ManageMenuItemModel/ManageMenuItemModel";

const RestaurantMenuItems = (props: { restaurantId?: string }) => {
  const { t } = useTranslation();
  const columns: DataColumn<MenuItem> = {
    picture: {
      title: "Image",
      width: 60,
      type: "image",
      key: "picture",
    },
    title: {
      title: "Name",
      sortable: false,
      type: "text",
      key: "title",
      width: "50%",
    },
    description: {
      title: "Description",
      sortable: false,
      type: "text",
      key: "description",
      width: "50%",
    },
    price: {
      title: "Price",
      sortable: false,
      type: "text",
      key: "price",
      width: "50%",
    },
    menuTags: {
      title: "Tags",
      type: "menutags",
      key: "menuTags",
      width: "50%",
    },
    // actions: {
    //   title: " ",
    //   type: "actions",
    //   key: "actions",
    //   align: "right",
    //   width: 30,
    // },
  };

  // SRVS
  const menuItemSrv = menuItemService();
  // PROPS
  const { restaurantId } = props;

  //STATES
  const [loadingGetList, setLoadingGetList] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<DataTableRow<MenuItem>[]>([]);

  const [menuItemAction, setMenuItemAction] = useState<
    | { type: "ADD"; restaurantId: string }
    | { type: "EDIT"; menuItem: MenuItem | DataTableRow<MenuItem> }
    | null
  >(null);

  useEffect(() => {
    if (menuItemSrv.ready && restaurantId && restaurantId !== "")
      getPage(filters.page, filters.limit);
  }, [menuItemSrv.ready, restaurantId]);

  const getPage = async (page: number, limit: number) => {
    setLoadingGetList(true);
    const resp = await menuItemSrv.getMenuItems(
      page,
      limit,
      restaurantId || ""
    );

    if (resp.success) {
      if (resp.success) {
        setMenuItems(
          resp.data?.map((item: any) => {
            return {
              ...item,
              picture: item.picture || "picture",
              menuTags: item.menuTags || [],
              // actions: [
              //   {
              //     key: "edit",
              //     icon: <FaPencilAlt />,
              //     label: "Edit",
              //     onClick: () => {
              //       // e.stopPropagation();
              //       onClickEdit(item);
              //     },
              //   },
              //   {
              //     key: "delete",
              //     icon: <FaTrash />,
              //     danger: true,
              //     label: "Delete",
              //     onClick: () => {
              //       // e.stopPropagation();
              //       onClickDelete(item);
              //     },
              //   },
              // ],
            };
          })
        );

        setTotal(resp.countAll);
      }
    } else
      message.error(
        "Something went wrong, please check your data and try again."
      );

    setLoadingGetList(false);
  };

  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [filters, setFilters] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 20,
  });

  const pagination: TablePaginationConfig = {
    total,
    pageSize,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal(total, _range) {
      return `total ${total}`;
    },
    position: ["bottomRight"],
    onChange(page, pageSize) {
      // console.log({ page, pageSize });
      setPageSize(pageSize);

      setFilters({ page, limit: pageSize });
    },
  };

  return (
    <>
      <Row align={"middle"} style={{ marginBottom: 10 }}>
        <Col flex={"auto"}>
          {/* <Typography.Title level={3} style={{ marginTop: 20 }}>
            Menu
          </Typography.Title> */}
        </Col>
        <Col flex={"100px"}>
          <Button
            type="primary"
            onClick={() => {
              setMenuItemAction({
                type: "ADD",
                restaurantId: restaurantId || "",
              });
            }}
          >
            {`${t(`actions.add`)} ${t(`general.new`)}`}{" "}
          </Button>
        </Col>
      </Row>

      <ListingTable
        data={{
          rows: menuItems,
          columns,
        }}
        onSelect={() => {}}
        size={"middle"}
        pagination={pagination}
        loading={loadingGetList}
        onRowClick={(_e, record) => {
          setMenuItemAction({ type: "EDIT", menuItem: record });
          // if (handleRecordClick) handleRecordClick(record as MenuItem);
        }}
      />

      {menuItemAction && (
        <ManageMenuItemModel
          open={true}
          action={menuItemAction}
          onOk={() => {
            getPage(
              menuItemAction.type === "ADD" ? 1 : filters.page,
              filters.limit
            );
            setMenuItemAction(null);
          }}
          onCancel={() => {
            setMenuItemAction(null);
          }}
        />
      )}
    </>
  );
};

export default RestaurantMenuItems;
