export const durationToMonthes = (duration: number | string) => {
  const new_duration = Number(duration) || 0;
  const years = Math.floor(new_duration / 12);
  const months = new_duration % 12;

  return {
    years,
    months,
    text: `${
      years > 0 ? `${years} year${years > 1 ? "s" : ""}` : ""
    } ${months} month${months > 1 ? "s" : ""}`,
  };
};

export const addTransparency = (color: string, alpha: number): string => {
  try {
    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);

    // Create the rgba() color code with transparency
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  } catch (error) {
    return "";
  }
};
export const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const padNumber = (num: number, size: number): string => {
  let paddedNum = num.toString();
  while (paddedNum.length < size) {
    paddedNum = `0${paddedNum}`;
  }
  return paddedNum;
};

export const validateTimeRange = (_rule, value, callback) => {
  const start = value[0];
  const end = value[1];
  if (start && end && start.isAfter(end)) {
    callback("The start time must be before the end time");
  } else {
    callback();
  }
};

export const parse = (str: any): object | null | any => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return null;
  }
};
