import React from "react";
import { FaList } from "react-icons/fa";
import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ManageRestaurant from "../../../components/restaurant/ManageRestaurant/ManageRestaurant";

const AddRestaurantPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <MainLayoutContent
      navigations={[
        {
          label: t("model.restaurant.plural"),
          link: "/restaurants",
          icon: <FaList />,
          isMain: true,
        },
        {
          label: `${t("actions.add")} ${t("general.new")}`,
          link: "/add",
        },
      ]}
    >
      <ManageRestaurant
        action={{ type: "ADD" }}
        onSave={(newResto) => {
          navigate(`/restaurants/view/${newResto?._id}`);
        }}
      />
    </MainLayoutContent>
  );
};

export default AddRestaurantPage;
