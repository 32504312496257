import React from "react";
import { NavLink } from "react-router-dom";
// import { Col, Row, Space, Typography } from "antd";
import { Col, Dropdown, Row, Space, Tooltip } from "antd";
import { FaAngleDown } from "react-icons/fa";

import { NavigationModel } from "../../ultils/models/models";
import "./top-menu.scss";
import { userService } from "../../services/user.srv";
import { IoLanguage } from "react-icons/io5";
import { lang_atom, scope_atom } from "../../store";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

const TopMenu = (props: { navigations: NavigationModel[] }) => {
  const { navigations } = props;
  const userSrv = userService();
  const [lang] = useAtom(lang_atom);
  const [scope] = useAtom(scope_atom);

  const { t } = useTranslation();

  return (
    <Row className="top-menu">
      <Col flex="auto">
        <div className="navigations">
          <Space align="center">
            {navigations.map((navigation, indx) => {
              return (
                <div
                  key={`navigation-${indx}`}
                  className={`navigation-item ${
                    navigation.isMain ? "main-nav" : ""
                  }`}
                >
                  <NavLink to={navigation.link}>
                    <span className="divider">
                      {navigation.isMain ? "" : " / "}
                    </span>{" "}
                    <Space align="center" size={10}>
                      {navigation.icon ? (
                        <span className="icon">{navigation.icon}</span>
                      ) : null}
                      <span className="label">
                        {typeof navigation.label === "string"
                          ? t(navigation.label)
                          : navigation.label}
                      </span>
                    </Space>
                  </NavLink>
                </div>
              );
            })}
          </Space>
        </div>
      </Col>
      <Col flex="none" style={{ marginRight: "26px" }}>
        <Tooltip
          title={lang.lang === "fr_FR" ? "Use English" : "Utiliser le Français"}
          placement="bottom"
        >
          <Space
            size={3}
            onClick={() => {
              userSrv.switchLang();
            }}
            style={{ cursor: "pointer" }}
          >
            <IoLanguage
              style={{
                color: "#444",
                fontSize: "20px",
                cursor: "pointer",
                transform: "translateY(5px)",
              }}
            />
            {lang.lang === "fr_FR" ? "Fr" : "En"}
          </Space>
        </Tooltip>
      </Col>
      {/* <Col flex="40px">
        <FaBell style={{ color: "#444", fontSize: "18px" }} />
      </Col> */}
      <Col flex="none">
        <Dropdown
          menu={{
            items: [
              // {
              //   key: "profile",
              //   label: "Profile",
              //   disabled: true,
              // },
              // { type: "divider" },
              {
                key: "logout",
                label: "Logout",
                onClick: () => {
                  userSrv.logOut();
                },
              },
            ],
          }}
        >
          <Space>
            {`${scope.user?.name}`} <FaAngleDown />
          </Space>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TopMenu;
