import React, { useEffect, useState } from "react";
import {
  Avatar,
  Popconfirm,
  Popover,
  Rate,
  Space,
  Spin,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import {
  FaCheckSquare,
  FaHeart,
  FaStore,
  FaTrash,
  FaWindowClose,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

import ListingTable from "../../Listing/ListingTable";
import { DataColumn } from "../../Listing/TypedDatatable";
import { Review, reviewService } from "../../../services/review/review.srv";

import { User } from "../../../global-types";
import { MenuItem } from "../../../services/menuItem/menuItem.srv";
import { Restaurant } from "../../../services/restaurant/restaurant.srv";
import dayjs from "dayjs";

// import logoSrc from "../../../assets/logo.svg";
import { UserOutlined } from "@ant-design/icons";
import "./list-reviews.scss";

interface CustomReview extends Review {
  actions?: JSX.Element; //MenuProps["items"];
  images: JSX.Element;
  rate: JSX.Element;
  user: JSX.Element;
  menuItem: JSX.Element;
}
const ListReviews = (props: {
  restaurantId?: string;
  refresh: string;
  onClickEdit?: (item: Review) => void;
  onClickDelete?: (item: Review) => void;
  handleRecordClick?: (item: Review) => void;
}) => {
  const { t } = useTranslation();

  const columns: DataColumn<CustomReview> = {
    images: {
      title: "Images",
      width: 50,
      type: "text",
      key: "images",
    },
    rate: {
      title: "Rate",
      type: "text",
      key: "rate",
    },
    user: {
      title: t("model.user.name"),
      type: "text",
      key: "user",
    },
    menuItem: {
      title: "Menu",
      type: "text",
      key: "menuItem",
    },
    status: {
      title: "Status",
      width: 100,
      type: "status",
      key: "status",
    },
    actions: {
      title: " ",
      type: "text",
      key: "actions",
      align: "right",
      width: 100,
    },
  };

  const { refresh, handleRecordClick, restaurantId } = props;

  // STATES
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<CustomReview[]>([]);
  const [filters, setFilters] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 20,
  });
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [loadingReview, setLoadingReview] = useState<boolean>(false);

  // SRVs
  const reviewSrv = reviewService();

  const pagination: TablePaginationConfig = {
    total,
    pageSize,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal(total, _range) {
      return `total ${total}`;
    },
    position: ["bottomRight"],
    onChange(page, pageSize) {
      // console.log({ page, pageSize });
      setPageSize(pageSize);

      setFilters({ page, limit: pageSize });
    },
  };

  useEffect(() => {
    if (reviewSrv.ready) getPage(1, filters.limit);
  }, [filters, reviewSrv.ready, refresh, restaurantId]);

  const changeReviewStatus = async (
    review: Review,
    status: "PENDING" | "BLOCKED" | "PUBLISHED"
  ) => {
    setLoadingReview(true);
    const resp = await reviewSrv.editReview(review._id, {
      ...review,
      userId:
        typeof review.userId === "string"
          ? review.userId
          : String(review.userId?._id || ""),
      menuId:
        typeof review.menuId === "string"
          ? review.menuId
          : String(review.menuId?._id || ""),
      status,
    });
    setLoadingReview(false);
    if (resp.success) getPage(filters.page, filters.limit);
  };

  // RENDERS
  const RenderUser = (user: string | User) => {
    if (typeof user === "string") return null;

    return (
      <>
        <Avatar
          src={user.picture && user.picture !== "" ? user.picture : undefined}
          style={{ marginRight: 5 }}
          icon={
            user.picture && user.picture !== "" ? undefined : <UserOutlined />
          }
        />
        <Typography.Text>{String(user.name)}</Typography.Text>
      </>
    );
  };

  const RenderMenuItem = (menu: string | MenuItem) => {
    if (!menu || typeof menu === "string") return null;

    const resto: Restaurant | string = menu?.restaurantId;
    return (
      <>
        <Typography.Text style={{ display: "block" }}>
          {menu.title}
        </Typography.Text>

        <Space align="center" size={5}>
          {typeof resto === "object" ? (
            <>
              <Avatar
                shape="square"
                src={
                  resto["logo"] && resto["logo"] !== ""
                    ? resto["logo"]
                    : undefined
                }
                icon={
                  resto["logo"] && resto["logo"] !== "" ? undefined : (
                    <FaStore style={{ marginTop: 5 }} />
                  )
                }
              />

              <Link
                to={`/restaurants/view/${resto["_id"]}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                {resto["name"] || ""}
              </Link>
            </>
          ) : (
            "-"
          )}
        </Space>
      </>
    );
  };

  const getPage = async (page: number, limit: number) => {
    setLoading(true);
    const resp = await reviewSrv.getReviews(page, limit, restaurantId);
    // console.log(resp);

    if (resp.success) {
      setRows(
        resp.data?.map((item: Review) => {
          return {
            ...item,
            images: (
              <div>
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                >
                  {item.pictures?.map((pic, indx) => {
                    if (!pic || pic === "") return null;
                    return (
                      <Avatar
                        key={`pic-${indx}-${item._id}`}
                        shape="square"
                        src={pic}
                      />
                    );
                  })}
                </Avatar.Group>
              </div>
            ),
            rate: (
              <>
                <Popover
                  placement="topLeft"
                  content={
                    <>
                      <div>
                        <Space size={5}>
                          <Rate
                            character={<FaHeart />}
                            allowHalf
                            disabled
                            value={item.serviceRating || 0}
                            // style={{ color: "pink" }}
                          />
                          <span>Service</span>
                        </Space>
                      </div>
                      <div>
                        <Space size={5}>
                          <Rate
                            character={<FaHeart />}
                            allowHalf
                            disabled
                            value={item.gastronomyRating || 0}
                            // style={{ color: "pink" }}
                          />
                          <span>Gastronomy</span>
                        </Space>
                      </div>
                      <div>
                        <Space size={5}>
                          <Rate
                            character={<FaHeart />}
                            allowHalf
                            disabled
                            value={item.ambienceRating || 0}
                            // style={{ color: "pink" }}
                          />
                          <span>Ambience</span>
                        </Space>
                      </div>
                    </>
                  }
                  title="Review Details"
                >
                  <>
                    <Rate
                      character={<FaHeart />}
                      allowHalf
                      disabled
                      value={
                        (Number(
                          item.serviceRating +
                            item.gastronomyRating +
                            item.ambienceRating
                        ) || 0) / 3
                      }
                    />
                  </>
                </Popover>

                <p style={{ color: "#777" }}>
                  {dayjs(item.postTime).format("D MMM YYYY, h:mm A")}
                </p>
                <Typography.Text style={{ display: "block" }}>
                  {item.comment}
                </Typography.Text>
              </>
            ),
            user: <>{RenderUser(item.userId)}</>,
            menuItem: <>{RenderMenuItem(item.menuId)}</>,
            actions: loadingReview ? (
              <Spin />
            ) : (
              <Space size={10} className="review-actions">
                <FaCheckSquare
                  className={`review-actions-check ${
                    item.status === "PUBLISHED" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeReviewStatus(item, "PUBLISHED");
                  }}
                />
                <FaWindowClose
                  className={`review-actions-close ${
                    item.status === "BLOCKED" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeReviewStatus(item, "BLOCKED");
                  }}
                />

                <Popconfirm
                  title={t("general.delete_permenent")}
                  description={item.comment}
                  onConfirm={async () => {
                    setLoadingReview(true);
                    const resp = await reviewSrv.deleteReview(item._id);
                    setLoadingReview(false);
                    if (resp.success) getPage(filters.page, filters.limit);
                  }}
                  okText={t("general.yes")}
                  cancelText={t("general.no")}
                >
                  {/* <Button danger style={{ marginLeft: 10 }}>
                    Delete
                  </Button> */}
                  <FaTrash
                    className="review-actions-trash"
                    style={{ marginLeft: 15 }}
                  />
                </Popconfirm>
              </Space>
            ),
          };
        })
      );

      setTotal(resp.countAll);
    }
    setLoading(false);
  };

  return (
    <ListingTable
      data={{
        rows: rows.map((item: any) => {
          return item;
        }),
        columns,
      }}
      onSelect={() => {}}
      size={"middle"}
      pagination={pagination}
      loading={loading}
      onRowClick={(_e, record: any) => {
        console.log("RECORD", record);

        if (handleRecordClick) handleRecordClick(record as Review);
      }}
    />
  );
};

export default ListReviews;
