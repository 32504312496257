import React from "react";

import { MainLayoutContent } from "../../../layouts/MainLayout/MainLayoutContent";
import { useTranslation } from "react-i18next";
// import { Row } from "../../../components/Listing/TypedDatatable";
import { FaUsers } from "react-icons/fa";
import ListUsers from "../../../components/user/ListUsers/ListUsers";

const UsersListPage = () => {
  const { t } = useTranslation();

  // STATES
  // const [refresh, setRefresh] = useState<string>("");
  // const [reviewModelAction, setReviewModelAction] = useState<
  //   { type: "ADD" } | { type: "EDIT"; review: Review | Row<Review> } | null
  // >(null);

  return (
    <div>
      <MainLayoutContent
        navigations={[
          {
            label: t("model.user.plural"),
            link: "/",
            icon: <FaUsers />,
            isMain: true,
          },
        ]}
      >
        <ListUsers
          refresh={"refresh"}
          handleRecordClick={() => {
            // console.log("handleRecordClick", item);
            // setReviewModelAction({ type: "EDIT", review: item });
          }}
        />

        {/* {tagModelAction && (
          <ManageMenuItemTagModel
            open={true}
            action={tagModelAction}
            onOk={() => {
              setRefresh(Math.random().toString());
              setTagModelAction(null);
            }}
            onCancel={() => {
              setTagModelAction(null);
            }}
          />
        )} */}
      </MainLayoutContent>
    </div>
  );
};

export default UsersListPage;
