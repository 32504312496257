import i18n from "i18next";
import { parse } from "../ultils/utils";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_translations from "./en/en-translations";
import fr_translations from "./fr/fr-translations";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en_US: {
        translations: en_translations,
      },
      fr_FR: {
        translations: fr_translations,
      },
      ar_EG: {
        translations: en_translations,
      },
    },
    fallbackLng: parse(localStorage?.getItem("LANG"))?.lang || "en_US",
    // debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: ".", // false use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
