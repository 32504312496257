import React from "react";

import { MainLayoutContent } from "../../layouts/MainLayout/MainLayoutContent";
import { FaChartPie, } from "react-icons/fa";
import "./dashboard.scss";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-page">
      <MainLayoutContent
        navigations={[
          {
            label: t("general.dashboard"),
            link: "/",
            icon: <FaChartPie />,
            isMain: true,
          },
        ]}
      >
      </MainLayoutContent>
    </div>
  );
};

export default DashboardPage;
