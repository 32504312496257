import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Space,
  Upload,
  message,
} from "antd";
import { Link } from "react-router-dom";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";

import { useTranslation } from "react-i18next";

import ManageMenuItemTagModel from "../../menuTag/ManageMenuItemTagModel/ManageMenuItemTagModel";
import { Row as DataTableRow } from "../../Listing/TypedDatatable";
import {
  MenuItemTag,
  menuTagService,
} from "../../../services/menuTag/menuTag.srv";

import {
  MenuItem,
  menuItemService,
} from "../../../services/menuItem/menuItem.srv";

import {
  ExclamationCircleFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./manage-menu-item.scss";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const { Item } = Form;

const ManageMenuItemModel = (props: {
  action:
    | { type: "ADD"; restaurantId: string }
    | { type: "EDIT"; menuItem: MenuItem | DataTableRow<MenuItem> };
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const form: FormInstance = Form.useForm()[0];
  const { t } = useTranslation();

  // PROPS
  const { action, open, onOk, onCancel } = props;

  // SRVs
  const menuItemSrv = menuItemService();
  const tagSrv = menuTagService();

  if (action.type === "EDIT" && !action.menuItem) return null;

  // STATES
  const [tags, setTags] = useState<MenuItemTag[]>([]);

  const [loadingtags, setLoadingTags] = useState<boolean>(false);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);

  const [tagModelAction, setTagModelAction] = useState<
    | { type: "ADD" }
    | { type: "EDIT"; menuItemTag: MenuItemTag | DataTableRow<MenuItemTag> }
    | null
  >(null);

  useEffect(() => {
    if (tagSrv.ready) getAllTags();
  }, [tagSrv.ready]);

  useEffect(() => {
    // if (action?.type === "EDIT") getAllTags();
  }, [action]);

  const getAllTags = async () => {
    setLoadingTags(true);
    const resp = await tagSrv.getMenuTags("MENU");
    if (resp.success) setTags(resp.data);
    setLoadingTags(false);
  };

  const handleChangePic: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoadingUpload(false);
        setImageUrl(url);
      });
    }
    if (info.file.status === "error") setLoadingUpload(false);
  };
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) message.error("Image must smaller than 2MB!");

    return isLt2M;
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: t("general.delete_permenent"),
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <strong>
            {action.type === "EDIT" ? action.menuItem?.title : ""}
          </strong>
        </>
      ),
      onOk() {
        return new Promise(async (resolve) => {
          const resp = await menuItemSrv.deleteMenuItem(
            action.type === "EDIT" ? String(action.menuItem._id) : ""
          );
          if (resp.success) onOk();
          resolve(null);
        }).catch((err) => console.log("Err delete", err));
      },
    });
  };

  return (
    <Modal
      className="manage-menu-item"
      title={`${action.type === "ADD" ? "Add" : "Edit"} Menu`}
      open={open}
      onCancel={onCancel}
      footer={[
        <Row>
          <Col flex={"auto"} style={{ textAlign: "left" }}>
            <Button danger onClick={showDeleteConfirm}>
              {t("actions.delete")}
            </Button>
          </Col>
          <Col flex={"200px"}>
            <Button onClick={onCancel}>{t("actions.discard")}</Button>
            <Button
              type="primary"
              loading={loadingSubmit}
              onClick={() => form.submit()}
            >
              {t("actions.save")}
            </Button>
          </Col>
        </Row>,
      ]}
      children={
        <>
          <Form
            form={form}
            layout="vertical"
            initialValues={
              action.type === "ADD"
                ? {
                    title: undefined,
                    menuTags: [],
                    price: undefined,
                    description: "",
                    picture: undefined,
                    restaurantId: action.restaurantId,
                  }
                : {
                    ...action.menuItem,
                    menuTags: Array.isArray(action.menuItem.menuTags)
                      ? action.menuItem.menuTags?.map((tag: any) => {
                          return typeof tag === "object" ? tag?._id : tag;
                        })
                      : [],
                  }
            }
            disabled={loadingtags}
            onFinish={async (values) => {
              console.log({ values });

              setLoadingSubmit(true);

              if (action.type === "ADD") {
                const resp = await menuItemSrv.addMenuItem({
                  ...values,
                  menuTags: values.menuTags || [],
                  price: Number(values.price || 0) || 0,
                  picture:
                    typeof values.picture === "string"
                      ? values.picture
                      : undefined,
                  description: values.description || "",
                  restaurantId: action.restaurantId,
                });

                if (resp.success) onOk();
                else setShowAlert(true);
              } else {
                const resp = await menuItemSrv.editMenuItem(
                  String(action.menuItem._id),
                  {
                    ...values,
                    menuTags: values.menuTags || [],
                    price: Number(values.price || 0) || 0,
                    picture:
                      typeof values.picture === "string"
                        ? values.picture
                        : undefined,
                    description: values.description || "",
                    restaurantId: action.menuItem.restaurantId,
                  } as MenuItem
                );

                if (resp.success) onOk();
                else setShowAlert(true);
              }

              setLoadingSubmit(false);
            }}
          >
            {showAlert && (
              <Alert
                message="Error"
                description="Something went wrong, please check your data and try again."
                onClose={() => setShowAlert(false)}
                type="error"
                closable
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              />
            )}
            <Row gutter={20}>
              <Col md={6}>
                <Item name="picture" label="Image">
                  <Upload
                    name="picture"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChangePic}
                    style={{ minHeight: 120 }}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <div>
                        {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Item>
              </Col>
              <Col md={18}>
                <Item name="title" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Item>
                <Item name="price" label="Price" rules={[{ required: true }]}>
                  <Input type="number" min={0} />
                </Item>
              </Col>
            </Row>

            <Item name={"menuTags"} label="Tags">
              <Checkbox.Group style={{ maxHeight: 130, overflowY: "auto" }}>
                {tags.map((tag, indx) => (
                  <div className="tag-check-wrapper" key={`menu-tag-${indx}`}>
                    <label>
                      <Space size={5}>
                        <Checkbox value={tag._id} />
                        {tag.picture && tag.picture !== "" && (
                          <img className="tag-img" src={tag.picture} alt={""} />
                        )}
                        <span>{tag.name}</span>
                      </Space>
                    </label>
                  </div>
                ))}
              </Checkbox.Group>
              {!loadingtags && !tags.length && (
                <Alert
                  type="warning"
                  action={
                    <Button
                      type="primary"
                      onClick={() => setTagModelAction({ type: "ADD" })}
                    >
                      Add Tag
                      {/* {t("actions.add")} Tag{" "} */}
                    </Button>
                  }
                  message={
                    <span>
                      There is no tags, please{" "}
                      <Link target="_blank" to="/tags">
                        create tags
                      </Link>{" "}
                      then reload this modal.
                    </span>
                  }
                />
              )}
            </Item>
            {!loadingtags && tags.length ? (
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={() => setTagModelAction({ type: "ADD" })}
                >
                  {/* Add Tag */}
                  {t("actions.add")} Tag{" "}
                </Button>
              </div>
            ) : null}

            <Item name="description" label="Description">
              <Input.TextArea />
            </Item>
          </Form>

          {tagModelAction && (
            <ManageMenuItemTagModel
              open={true}
              action={{ ...tagModelAction, tagType: "MENU" }}
              onOk={() => {
                getAllTags();
                setTagModelAction(null);
              }}
              onCancel={() => {
                setTagModelAction(null);
              }}
            />
          )}
        </>
      }
    />
  );
};
export default ManageMenuItemModel;
