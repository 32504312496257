import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";

import { ConfigProvider } from "antd";
import Router from "./router";
import "./stylesheets/main.scss";
import LoginPage from "./pages/login/Login.page";
import { useAtom } from "jotai";
import { scope_atom } from "./store";
import { initAxios } from "./services/config";
import { userService } from "./services/user.srv";
import { isExpired } from "react-jwt";


// ************ TO ASK : WILL WE IMPLIMENT CUBE ??
// import cube from "@cubejs-client/core";
// // import WebSocketTransport from "@cubejs-client/ws-transport";
// const cubeApi = cube("token", {
//   apiUrl: "https://cube.gympro.tech/cubejs-api/v1",
// });
// import { CubeProvider } from "@cubejs-client/react";
// ************

export const App = () => {
  const [scope] = useAtom(scope_atom);
  const userSrv = userService();

  const [expiration_timeout_id, setExpTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  // ADD AUTH TOOKEN TO ALL REQUESTS
  useEffect(() => {
    if (scope?.token && scope?.token !== "") initAxios(scope?.token);
  }, [scope?.token]);

  // GET CONNECTED PROFILE
  // useEffect(() => {
  //   if (scope.token !== "" && !scope.user && userSrv.ready) {
  //     const updateUserProfile = async () => {
  //       const profile = await userSrv.getProfile();
  //       // console.log("PROFILE", profile);

  //       if (profile.success) setScope({ ...scope, user: profile.record });
  //     };

  //     updateUserProfile();
  //   }
  // }, [userSrv.ready]);

  // WATCH TOKEN EXPIRATION TIME FOR EACH [LOCATION CHANGED]
  useEffect(() => {
    if (
      !scope?.connected ||
      (scope?.connected && isExpired(scope.accessToken))
    ) {
      userSrv.logOut(location.pathname !== "/");
    } else {
      // handleExpirationTokenTimeOut(scope.exp || 0);
      const exp_duration =
        (scope.exp || 0) * 1000 - new Date().getTime() - 3000;

      setExpTimeoutId(
        setTimeout(
          () => userSrv.logOut(),
          exp_duration > 0 ? exp_duration : 100
        )
      );
    }

    // ON CMP UNMOUNT
    return () => {
      if (expiration_timeout_id) clearInterval(expiration_timeout_id);
    };
  }, [location?.href]);

  // useEffect(() => {
  //   requestPermission();
  // }, []);

  return scope.connected && scope.user ? (
    // <CubeProvider cubejsApi={cubeApi}>
    <ConfigProvider>
      <Router />
    </ConfigProvider>
    // </CubeProvider>
  ) : (
    <LoginPage />
  );
};
