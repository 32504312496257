import React, { useState } from "react";
import { Alert, Button, Card, Form, Input } from "antd";
import { LockFilled, MailFilled } from "@ant-design/icons";
import { useAtom } from "jotai";
import { decodeToken } from "react-jwt";
import "./login.scss";
import { scope_atom } from "../../store";
import { initAxios } from "../../services/config";
import { userService } from "../../services/user.srv";
import { useTranslation } from "react-i18next";

import logoSrc from "../../assets/logo.svg";

const { Item } = Form;
const Login = (props: { onLoggedIn?: (token: string) => void }) => {
  // PROPS
  const { onLoggedIn } = props;

  // STATES
  const [errorLogin, setErrorLogin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // ATOMS
  const [_scope, setScope] = useAtom(scope_atom);

  // SRVs
  const userServ = userService();
  const { t } = useTranslation();

  const onSubmit = async (values: { email: string; password: string }) => {
    setLoading(true);

    try {
      const loginResp = await userServ.login(values.email, values.password);

      if (loginResp.success) {
        const token = loginResp.record.accessToken;
        const bearerToken = `Bearer ${token}`;
        // console.log("TOKEN", token);

        const decoded_token: { exp: number } | null = decodeToken(token);
        // console.log("DECODED TOKEN", decoded_token);

        const profileResp = await userServ.getProfile(bearerToken);
        // console.log("profileResp", profileResp);

        if (
          profileResp.success &&
          profileResp?.record &&
          profileResp?.record.role === "SUPER_ADMIN"
        ) {
          initAxios(bearerToken);
          setScope({
            connected: true,
            accessToken: token,
            token: bearerToken,
            exp: decoded_token?.exp || 100,
            user: profileResp.record,
          });

          if (onLoggedIn) onLoggedIn(bearerToken);
        } else setErrorLogin(true);
      } else {
        setErrorLogin(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorLogin(true);
      console.log(error);
    }
  };

  return (
    <>
      <div className="login-container">
        <Card className="container-card">
          <img src={logoSrc} className="logo" alt="MenuPro" />
          <Form
            onFinish={onSubmit}
            layout="vertical"
            initialValues={{
              email: "super-admin@gmail.com",
              password: "123456",
              // email: "",
              // password: "",
            }}
          >
            {errorLogin && (
              <Alert
                message="Login Error"
                description="Invalid email or password. Please try again."
                type="error"
                closable
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "10px",
                }}
              />
            )}

            <Item
              name={"email"}
              label={<span className="label">{t("fields.email")}</span>}
              rules={[
                {
                  required: true,
                  message: t("general.input_your", {
                    field: t("fields.email"),
                  }),
                },
              ]}
            >
              <Input
                className="input"
                placeholder={t("fields.email")}
                prefix={<MailFilled className="site-form-item-icon" />}
              />
            </Item>
            <Item
              name={"password"}
              label={<span className="label">{t("fields.password")}</span>}
              rules={[
                {
                  required: true,
                  message: t("general.input_your", {
                    field: t("fields.password"),
                  }),
                },
              ]}
            >
              <Input
                className="input"
                placeholder={t("fields.password")}
                type="password"
                prefix={<LockFilled />}
              />
            </Item>
            <Button
              loading={loading}
              htmlType={"submit"}
              type="primary"
              className="submit-btn"
            >
              Login
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;
