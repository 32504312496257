import React, { useEffect, useState } from "react";
import {
  Restaurant,
  restaurantService,
} from "../../../services/restaurant/restaurant.srv";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Space,
  Tabs,
  Tag,
  message,
} from "antd";
import RestaurantMenuItems from "../RestaurantMenuItems/RestaurantMenuItems";
import { useTranslation } from "react-i18next";
import ListReviews from "../../review/ListReviews/ListReviews";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
const ViewRestaurant = (props: {
  restaurantId?: string;
  onRestaurantDetails: (restaurant: Restaurant) => void;
}) => {
  const { t } = useTranslation();
  // SRVS
  const restoSrv = restaurantService();
  // PROPS
  const { restaurantId, onRestaurantDetails } = props;

  //STATES
  const [loadingGetOne, setLoadingGetOne] = useState<boolean>(false);
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  useEffect(() => {
    if (restoSrv.ready && restaurantId && restaurantId !== "")
      getOne(restaurantId);
  }, [restoSrv.ready, restaurantId]);

  const getOne = async (restaurantId: string) => {
    setLoadingGetOne(true);
    const resp = await restoSrv.getRestaurant(restaurantId);
    // console.log(resp);

    if (resp.success) {
      onRestaurantDetails(resp.record.restaurant);
      setRestaurant(resp.record.restaurant);
    } else
      message.error(
        "Something went wrong, please check your data and try again."
      );

    setLoadingGetOne(false);
  };

  return (
    <>
      <Badge.Ribbon
        text={t(`general.${restaurant?.status?.toLowerCase() || "pending"}`)}
        color={
          !restaurant?.status || restaurant?.status === "PENDING"
            ? "yellow"
            : restaurant?.status === "BLOCKED"
            ? "red"
            : "green"
        }
      >
        <Card
          loading={loadingGetOne}
          cover={
            <img src={restaurant?.cover} alt="" style={{ maxHeight: 400 }} />
          }
        >
          <Card.Meta
            title={restaurant?.name}
            description={restaurant?.slogan}
            avatar={
              <Avatar
                src={restaurant?.logo || ""}
                style={{ width: 80, height: 80 }}
              />
            }
          />

          <Row style={{ marginTop: 15 }}>
            <Col flex={"auto"}>
              <Space size={5}>
                {restaurant?.tags?.map((tag, indx) => {
                  if (!tag?.name) return null;
                  return (
                    <Tag color="blue" id={`resto-tag-${indx}`}>
                      {tag?.name}
                    </Tag>
                  );
                })}
              </Space>
            </Col>
            <Col flex={"100px"}>
              <div style={{ textAlign: "right" }}>
                <Link to={`/restaurants/edit/${restaurantId}`}>
                  <Button type="dashed">
                    {" "}
                    <FaEdit /> {t("actions.edit")}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Card>
      </Badge.Ribbon>

      <Tabs
        style={{ marginTop: 15, padding: 0 }}
        type="card"
        items={[
          {
            label: `Menu`,
            key: "resto-menu-tab",
            children: (
              <Card bodyStyle={{ padding: 10 }}>
                {restaurant && (
                  <RestaurantMenuItems restaurantId={restaurant._id} />
                )}
              </Card>
            ),
          },
          {
            label: t("model.review.plural"),
            key: "resto-reviews-tab",
            children: (
              <>
                {restaurant && (
                  <ListReviews refresh="" restaurantId={restaurant._id} />
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default ViewRestaurant;
