import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  TablePaginationConfig,
} from "antd";
import { useTranslation } from "react-i18next";

import ListingTable from "../../Listing/ListingTable";
import { DataColumn } from "../../Listing/TypedDatatable";

import { User } from "../../../global-types";
import dayjs from "dayjs";

import "./list-users.scss";
import { userService } from "../../../services/user.srv";
import { FaTrash } from "react-icons/fa";
import { MdPassword } from "react-icons/md";

interface CustomUser extends User {
  actions?: JSX.Element; //MenuProps["items"];
  createdAt?: string;
}
const ListUsers = (props: {
  refresh: string;
  onClickEdit?: (item: User) => void;
  onClickDelete?: (item: User) => void;
  handleRecordClick?: (item: User) => void;
}) => {
  const { t } = useTranslation();

  const columns: DataColumn<CustomUser> = {
    picture: {
      title: "Images",
      width: 60,
      type: "image",
      key: "picture",
    },
    name: {
      title: t("fields.name"),
      type: "text",
      key: "name",
    },
    email: {
      title: t("fields.email"),
      type: "text",
      key: "email",
    },
    createdAt: {
      title: "Created At",
      type: "datetime",
      format: "D MMM YYYY, h:mm A",
      key: "createdAt",
    },
    gender: {
      title: t("fields.gender"),
      type: "text",
      key: "gender",
    },
    actions: { title: "", type: "text", key: "actions" },
  };

  const { refresh, handleRecordClick } = props;

  // STATES
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<CustomUser[]>([]);
  const [filters, setFilters] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 20,
  });
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [showChangePassword, setShowChangePassword] = useState<
    User | undefined
  >(undefined);

  // SRVs
  const userSrv = userService();

  const form: FormInstance = Form.useForm()[0];

  const pagination: TablePaginationConfig = {
    total,
    pageSize,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal(total, _range) {
      return `total ${total}`;
    },
    position: ["bottomRight"],
    onChange(page, pageSize) {
      // console.log({ page, pageSize });
      setPageSize(pageSize);

      setFilters({ page, limit: pageSize });
    },
  };

  useEffect(() => {
    if (userSrv.ready) getPage(1, filters.limit);
  }, [filters, userSrv.ready, refresh]);

  // RENDERS
  // const RenderUser = (user: User) => {
  //   return (
  //     <>
  //       <Avatar
  //         src={user.picture && user.picture !== "" ? user.picture : undefined}
  //         style={{ marginRight: 5 }}
  //         icon={
  //           user.picture && user.picture !== "" ? undefined : <UserOutlined />
  //         }
  //       />
  //       <Typography.Text>{String(user.name)}</Typography.Text>
  //     </>
  //   );
  // };

  const getPage = async (page: number, limit: number) => {
    setLoading(true);
    const resp = await userSrv.getUsers(page, limit);
    // console.log(resp);

    if (resp.success) {
      setRows(
        resp.data?.map((item: User) => {
          return {
            ...item,
            createdAt: dayjs(
              new Date(parseInt(item._id.substring(0, 8), 16) * 1000)
            ).format("D MMM YYYY, h:mm A"),
            actions: (
              <>
                <Space size={20} className="review-actions">
                  <MdPassword
                    style={{ cursor: "pointer", color: "#333" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowChangePassword(item);
                    }}
                  />

                  {item.role !== "SUPER_ADMIN" && (
                    <Popconfirm
                      title={t("general.delete_permenent")}
                      description={item.name}
                      onConfirm={async () => {
                        setLoading(true);
                        const resp = await userSrv.deleteUser(item._id);
                        setLoading(false);
                        if (resp.success) getPage(filters.page, filters.limit);
                      }}
                      okText={t("general.yes")}
                      cancelText={t("general.no")}
                    >
                      <FaTrash
                        className="review-actions-trash"
                        style={{ marginLeft: 15 }}
                      />
                    </Popconfirm>
                  )}
                </Space>
              </>
            ),
            // user: <>{RenderUser(item.userId)}</>,
          };
        })
      );

      setTotal(resp.countAll);
    }
    setLoading(false);
  };

  return (
    <>
      <ListingTable
        data={{
          rows: rows?.map((item: any) => {
            return item;
          }),
          columns,
        }}
        onSelect={() => {}}
        size={"middle"}
        pagination={pagination}
        loading={loading}
        onRowClick={(_e, record: any) => {
          // console.log("RECORD", record);

          if (handleRecordClick) handleRecordClick(record as User);
        }}
      />
      {!showChangePassword ? null : (
        <Modal
          title={`Change Password ${showChangePassword.name}`}
          open={showChangePassword ? true : false}
          onCancel={() => {
            setShowChangePassword(undefined);
            form.setFieldsValue({ repass: "", newPassword: "" });
          }}
          footer={[
            <div style={{ textAlign: "right" }}>
              <Button onClick={() => setShowChangePassword(undefined)}>
                {t("actions.discard")}
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={() => form.submit()}
              >
                {t("actions.save")}
              </Button>
            </div>,
          ]}
        >
          <Form
            form={form}
            initialValues={{ newPassword: "", repass: "", username: "" }}
            layout="vertical"
            onFinish={async (values) => {
              if (values?.newPassword && values?.newPassword !== "") {
                setLoading(true);
                const resp = await userSrv.changePassword(
                  showChangePassword._id,
                  values.newPassword
                );

                setLoading(false);

                if (resp.success) {
                  form.setFieldsValue({ repass: "", newPassword: "" });
                  setShowChangePassword(undefined);
                }
              }
            }}
          >
            <Form.Item
              rules={[{ required: true }]}
              label="New password"
              name="newPassword"
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="repass"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ListUsers;
