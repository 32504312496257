import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { Provider as JotaiProvider } from "jotai";

import { App } from "./src/App";

import enUS from "antd/es/locale/en_US";
import arEG from "antd/es/locale/ar_EG";
import frFr from "antd/es/locale/fr_FR";

import "./src/locales/i18n";

const container = document.getElementById("app");
const root = createRoot(container);

const getLoacalBylanguage = () => {
  const lang = localStorage?.getItem("lang") || navigator.language;

  let direction = "ltr";
  let locale = enUS;

  switch (lang) {
    case "ar_EG":
      direction = "rtl";
      locale = arEG;
      break;
    case "fr_FR":
      locale = frFr;
      break;

    default:
      locale = enUS;
      break;
  }

  return { locale, direction };
};

root.render(
  <ConfigProvider {...getLoacalBylanguage()}>
    <JotaiProvider>
      <App />
    </JotaiProvider>
  </ConfigProvider>
);
