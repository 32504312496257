import React from "react";
import Login from "../../components/Login/Login";

const LoginPage = () => {
  return (
    <>
      <Login
        // onLoggedIn={(token: string) => {
        //   localStorage.setItem("gympro-token", token);

        //   localStorage.setItem("gympro-connected", "true");
        //   location.reload();
        // }}
      />
    </>
  );
};

export default LoginPage;
