import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  message,
} from "antd";
import "./manage-resto.scss";
import {
  Restaurant,
  restaurantService,
} from "../../../services/restaurant/restaurant.srv";
import Dragger from "antd/es/upload/Dragger";
import { UploadOutlined } from "@ant-design/icons";
import Map from "../../Map/Map";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as Minio from "minio";
import { useAtom } from "jotai";
import { scope_atom } from "../../../store";
import {
  MAP_KEY,
  MINIO_ACCESS_KEY,
  MINIO_SECRET_KEY,
  MINIO_SERVER,
} from "../../../services/config";
import {
  MenuItemTag,
  menuTagService,
} from "../../../services/menuTag/menuTag.srv";
import { Row as DataTableRow } from "../../Listing/TypedDatatable";
import ManageMenuItemTagModel from "../../menuTag/ManageMenuItemTagModel/ManageMenuItemTagModel";

const ManageRestaurant = (props: {
  action:
    | { type: "ADD" }
    | {
        type: "EDIT";
        restaurantId: string;
        onRestaurantDetails?: (restaurant: Restaurant) => void;
      };
  onCancel?: (record?: Restaurant) => void;

  onSave: (newResto: Restaurant) => void;
}) => {
  const form: FormInstance = Form.useForm()[0];

  // SRVs
  const restoSrv = restaurantService();
  const tagSrv = menuTagService();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [scope] = useAtom(scope_atom);

  // PROPS
  const { action, onSave } = props;

  // STATES
  const [loading, setLoading] = useState(false);
  const [loadingGetOne, setLoadingGetOne] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [selectedCover, setSelectedCover] = useState<string | null>(null);
  const [selectedLogo, setSelectedLogo] = useState<string | null>(null);
  const [lat, setLat] = useState<number>(35.8245);

  const [lng, setLng] = useState<number>(10.6346);
  const [address, setAddress] = useState<string>("");

  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

  const [tagModelAction, setTagModelAction] = useState<
    | { type: "ADD" }
    | { type: "EDIT"; menuItemTag: MenuItemTag | DataTableRow<MenuItemTag> }
    | null
  >(null);
  const [tags, setTags] = useState<MenuItemTag[]>([]);
  const [loadingtags, setLoadingTags] = useState<boolean>(false);

  const customUpload = async (
    fileName: "logo" | "cover",
    { file, onSuccess, onError }: any
  ) => {
    console.log({ file, onSuccess, onError });
    console.log("file.path", file.path);

    try {
      const minioClient = new Minio.Client({
        endPoint: MINIO_SERVER,
        port: 443,
        useSSL: false,
        accessKey: MINIO_ACCESS_KEY,
        secretKey: MINIO_SECRET_KEY,
      });

      minioClient.putObject(
        "menupro",
        file.name,
        file,
        // { "Content-Type": file.type },
        (err, etag) => {
          if (err) {
            console.error("Error uploading file:", err);
          } else {
            console.log("File uploaded successfully:", etag);
          }
        }
      );

      if (fileName === "logo") setSelectedLogo("url.href");
      else setSelectedCover("url.href");
      form.setFieldValue(fileName, "url.href");

      onSuccess("url.href");
    } catch (error) {
      console.log("ERROR UPLOAD FILE", error);
      onError(error);
    }

    // const storageRef = ref(
    //   storage,
    //   `/uploads${`/${scope?.user?.salleId}` || ""}/admin/event/${nanoid(8)}_${
    //     file.name
    //   }`
    // );
    // try {
    //   await uploadBytes(storageRef, file);
    //   const downloadURL = await getDownloadURL(storageRef);
    //   let url = new URL(downloadURL);
    //   url.host = "cdn.gympro.tech";
    //   form.setFieldValue("image", url.href);
    //   setSelectedImage(url.href);
    //   onSuccess(url.href);
    // } catch (error) {
    //   onError(error);
    // }
  };

  // HOOKS
  useEffect(() => {
    if (restoSrv.ready && action.type === "EDIT" && action.restaurantId !== "")
      if (!loadingGetOne) getOne(action.restaurantId);
  }, [restoSrv.ready, action.type]);

  useEffect(() => {
    if (tagSrv.ready) getAllTags();
  }, [tagSrv.ready]);

  const getAllTags = async () => {
    setLoadingTags(true);
    const resp = await tagSrv.getMenuTags("RESTAURANT");
    if (resp.success) setTags(resp.data);
    setLoadingTags(false);
  };

  const getOne = async (restaurantId: string) => {
    setLoadingGetOne(true);
    const resp = await restoSrv.getRestaurant(restaurantId);
    console.log(resp);

    if (
      resp.success &&
      action.type === "EDIT" &&
      resp.record.restaurant &&
      action.onRestaurantDetails
    ) {
      form.setFieldsValue({
        ...resp.record.restaurant,
        tags: resp.record.restaurant?.tags?.map((tag) => tag?._id),
      });

      setRestaurant(resp.record.restaurant);
      setAddress(resp.record.restaurant.address);
      setLat(resp.record.restaurant.lat);
      setLng(resp.record.restaurant.lng);

      setSelectedCover(resp.record.restaurant.cover || null);
      setSelectedLogo(resp.record.restaurant.logo || null);

      if (action.onRestaurantDetails)
        action.onRestaurantDetails(resp.record.restaurant);
    } else
      message.error(
        "Something went wrong, please check your data and try again."
      );

    setLoadingGetOne(false);
  };

  return (
    <>
      <Card loading={loadingGetOne}>
        <Form
          className="manage-resto"
          layout="vertical"
          onFinish={async (values) => {
            setLoading(true);

            const resp =
              action.type === "ADD"
                ? await restoSrv.addRestaurant({
                    ...values,
                    status: "PENDING",
                    address,
                    logo: selectedLogo || "",
                    cover: selectedCover || "",
                    suggested_by: scope.user?._id,
                    lng,
                    lat,
                    tags: values.tags || [],
                  })
                : await restoSrv.editRestaurant(action.restaurantId, {
                    ...values,
                    status: restaurant?.status,
                    address,
                    logo: selectedLogo || "",
                    cover: selectedCover || "",
                    suggested_by: scope.user?._id,
                    lng,
                    lat,
                    tags: values.tags || [],
                  });

            if (resp.success) {
              setLoading(false);
              onSave(resp.record);
              // onCancel(resp.record);
            } else {
              setLoading(false);
              setShowAlert(true);
            }
          }}
          // onValuesChange={(changes, allValues) => {
          //   console.log({ changes, allValues });
          // }}
          form={form}
          style={{ width: "100%" }}
          initialValues={
            action.type === "EDIT" && restaurant
              ? {
                  ...restaurant,
                  tags: restaurant.tags?.map((tag) => tag?._id),
                }
              : {
                  name: "",
                  slogan: "",
                  address: "",
                  lat,
                  lng,
                  logo: "",
                  cover: "",
                  status: "PENDING",
                  tags: [],
                }
          }
        >
          {showAlert && (
            <Alert
              message="Error"
              description="Something went wrong, please check your data and try again."
              onClose={() => setShowAlert(false)}
              type="error"
              closable
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            />
          )}

          <Row gutter={20}>
            <Col md={24}>
              <Form.Item
                name="cover"
                // labelCol={labelCol}
                // wrapperCol={wrapperCol}
                label={
                  <>
                    <span>Cover</span>
                    <span
                      style={{
                        color: "#777",
                        display: "block",
                        marginLeft: 5,
                      }}
                    >
                      This will be displayed on dashboard
                    </span>
                  </>
                }
              >
                {selectedCover ? (
                  <div>
                    {/* <img
                      src={selectedCover}
                      style={{ maxWidth: "200px", maxHeight: "150px" }}
                    ></img>
                    <Button
                      onClick={() => {
                        form.setFieldValue("image", undefined);
                        setSelectedCover(null);
                      }}
                    >
                      <FaTrashAlt color="red" />
                    </Button> */}
                  </div>
                ) : (
                  <Dragger
                    customRequest={(options) => {
                      customUpload("cover", options);
                    }}
                    accept="image/*"
                    showUploadList={false}
                    multiple={false}
                    // style={{minHeight: 150}}
                  >
                    <p className="ant-upload-drag-icon">
                      {/* <UploadOutlined /> */}
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop an image here, or click to select one
                    </p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Row gutter={20}>
                <Col flex="150px">
                  {/* <UploadFile /> */}

                  {/* <input
                    type="file"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        const fileUploaded = e.target.files[0];

                        const minioClient = new Minio.Client({
                          endPoint: "menupro-storage.mahd.dev",
                          port: 443,
                          useSSL: false,
                          accessKey: "app",
                          secretKey: "76GIUYB99N86V765",
                        });
                        minioClient.presignedPutObject(
                          "menupro",
                          fileUploaded.name,
                          (err, url) => {
                            if (err) console.log("ERR", err);
                            if (url) {
                              console.log(url);

                              fetch(url, {
                                method: "PUT",
                                body: fileUploaded,
                              })
                                .then(() => {
                                  console.log("FILE UPLOADED");
                                })
                                .catch((e) => {
                                  console.error(e);
                                });

                              // minioClient.fPutObject(
                              //   "menupro",
                              //   fileUploaded.name,
                              //   url,
                              //   { "Content-Type": fileUploaded.type },
                              //   (err, etag) => {
                              //     if (err) {
                              //       console.error("Error uploading file:", err);
                              //     } else {
                              //       console.log("File uploaded successfully:", etag);
                              //     }
                              //   }
                              // );
                            }
                          }
                        );
                      }
                    }}
                  /> */}
                  <Form.Item name="logo" label="Logo">
                    {selectedLogo ? (
                      <div>
                        {/* <img
                      src={selectedLogo}
                      style={{ maxWidth: "200px", maxHeight: "150px" }}
                    ></img>
                    <Button
                      onClick={() => {
                        form.setFieldValue("image", undefined);
                        setSelectedCover(null);
                      }}
                    >
                      <FaTrashAlt color="red" />
                    </Button> */}
                      </div>
                    ) : (
                      <Dragger
                        customRequest={(options) => {
                          customUpload("logo", options);
                        }}
                        accept="image/*"
                        showUploadList={false}
                        multiple={false}
                        name="logo"
                        // action="https://menupro-storage.mahd.dev/menupro" // Replace with your MinIO server endpoint
                        // headers={{
                        //   authorization: "76GIUYB99N86V765",
                        //   // authorization: "Bearer " + "76GIUYB99N86V765", // Replace with any authorization token required by your server
                        // }}
                        onChange={(info) => {
                          console.log("CHANGE", info.file);
                        }}
                        style={{ minHeight: 120 }}
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined />
                        </p>
                      </Dragger>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Slogan" name="slogan">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true }]}
              >
                {address !== "" && (
                  <span style={{ color: "#777", fontSize: 12 }}>
                    {lat},{lng}
                  </span>
                )}
                <Input readOnly disabled value={address} />
              </Form.Item>

              <Form.Item name={"tags"} label="Tags">
                <Checkbox.Group style={{ maxHeight: 130, overflowY: "auto" }}>
                  {tags?.map((tag, indx) => (
                    <div
                      className="tag-check-wrapper"
                      key={`resto-tag-${indx}`}
                    >
                      <label>
                        <Space size={5}>
                          <Checkbox value={tag._id} />
                          {tag.picture && tag.picture !== "" && (
                            <img
                              className="tag-img"
                              src={tag.picture}
                              alt={""}
                            />
                          )}
                          <span>{tag.name}</span>
                        </Space>
                      </label>
                    </div>
                  ))}
                </Checkbox.Group>
                {!loadingtags && !tags?.length && (
                  <Alert
                    type="warning"
                    action={
                      <Button
                        type="primary"
                        onClick={() => setTagModelAction({ type: "ADD" })}
                      >
                        Add Tag
                        {/* {t("actions.add")} Tag{" "} */}
                      </Button>
                    }
                    message={
                      <span>
                        There is no tags, please{" "}
                        <Link target="_blank" to="/tags">
                          create tags
                        </Link>{" "}
                        then reload this modal.
                      </span>
                    }
                  />
                )}
              </Form.Item>
              {!loadingtags && tags?.length ? (
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => setTagModelAction({ type: "ADD" })}
                  >
                    {/* Add Tag */}
                    {t("actions.add")} Tag{" "}
                  </Button>
                </div>
              ) : null}
            </Col>
            <Col md={12}>
              {action.type === "EDIT" && !restaurant ? null : (
                <Map
                  apiKey={MAP_KEY}
                  initPosition={{ lat, lng }}
                  onMarkerChanged={(result, position) => {
                    console.log("ADDRESS", result);

                    form.setFieldValue("address", result.formatted_address);
                    setLat(position.lat);
                    setLng(position.lng);
                    setAddress(result.formatted_address);
                    form.setFieldValue("lat", position.lat);
                    form.setFieldValue("lng", position.lng);
                  }}
                />
              )}
            </Col>
          </Row>

          <div style={{ textAlign: "right", marginTop: 20 }}>
            <Button
              onClick={() => {
                navigate("/restaurants");
              }}
            >
              {t("actions.discard")}
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => form.submit()}
              loading={loading}
            >
              {t("actions.save")}
            </Button>
          </div>
        </Form>
      </Card>

      {tagModelAction && (
        <ManageMenuItemTagModel
          open={true}
          action={{ ...tagModelAction, tagType: "RESTAURANT" }}
          onOk={() => {
            getAllTags();
            setTagModelAction(null);
          }}
          onCancel={() => {
            setTagModelAction(null);
          }}
        />
      )}
    </>
  );
};

export default ManageRestaurant;
