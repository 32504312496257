import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { scope_atom } from "../store";

export const useTokenHook = () => {
  const [scope, _setScope] = useAtom(scope_atom);
  const [token, setToken] = useState<string | null | undefined>("");

  useEffect(() => {
    // console.log("TOKEN LENGTH", scope?.token?.length);

    setToken(scope?.token);
  }, [scope?.token]);

  return { token };
};
