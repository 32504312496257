import React, { useEffect, useState } from "react";
import { FaChartPie, FaCog, FaStore, FaTags, FaUsers } from "react-icons/fa";
import { MdReviews } from "react-icons/md";
import { Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import { NavLink } from "react-router-dom";

import "./main-layout.scss";
import { scope_atom } from "../../store";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import logoSrc from "../../assets/logo.svg";

const { Content, Sider } = Layout;

const MainLayout = (props: {
  children: any;
  defaultSelectedKeys?: string[];
}) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const location = useLocation();

  // PROPS
  const { children, defaultSelectedKeys } = props;

  // STATES
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  // ATOMS
  const [scope] = useAtom(scope_atom);

  // const selectedKeys = useMemo(() => {
  //   console.log("location.pathname", location.search);

  //   try {
  //     const pathname = location.pathname.split("/")[1];
  //     if (pathname === "") return [`menu-dashboard`];
  //     else return [`menu-${pathname}`];
  //   } catch (error) {
  //     return [];
  //   }
  // }, [location.search]);

  // HOOKS
  useEffect(() => {
    try {
      const pathname = location.pathname.split("/")[1];
      if (pathname === "") setSelectedKeys([`menu-dashboard`]);
      else setSelectedKeys([`menu-${pathname}`]);
    } catch (error) {
      setSelectedKeys([]);
    }
  }, [location.pathname]);

  // RELATED VARIABLES
  let menItems: MenuProps["items"] = [];
  // console.log("==> scope?.user?.role", scope?.user?.role);

  switch (scope?.user?.role?.toUpperCase()) {
    case "USER":
    case "SUPER_ADMIN":
      menItems = [
        {
          key: "menu-dashboard",
          label: <NavLink to={"/"}>{t("general.dashboard")}</NavLink>,
          icon: <FaChartPie />,
        },
        {
          key: "menu-restaurants",
          label: (
            <NavLink to={"/restaurants"}>
              {t("model.restaurant.plural")}
            </NavLink>
          ),
          icon: <FaStore />,
        },
        {
          key: "menu-reviews",
          label: <NavLink to={"/reviews"}>{t("model.review.plural")}</NavLink>,
          icon: <MdReviews />,
        },
        {
          key: "menu-tags",
          label: <NavLink to={"/tags"}>{t("model.tag.plural")}</NavLink>,
          icon: <FaTags />,
        },
        {
          key: "menu-users",
          label: <NavLink to={"/users"}>{t("model.user.plural")}</NavLink>,
          icon: <FaUsers />,
        },
      ];
      break;
    case "MANAGER":
    case "ADMIN":
      menItems = [
        {
          key: "menu-dashboard",
          label: <NavLink to={"/"}>{t("general.dashboard")}</NavLink>,
          icon: <FaChartPie />,
        },
        {
          type: "divider",
        },
        // {
        //   key: "menu-notifications",
        //   label: <NavLink to={"/notifications"}>Notifications</NavLink>,
        //   icon: <FaBell />,
        // },
        {
          key: "menu-settings",
          label: <NavLink to={"/settings"}>{t("general.settings")}</NavLink>,
          icon: <FaCog />,
        },
      ];
      break;

    default:
      menItems = [];
      break;
  }
  return (
    <Layout className="main-layout">
      <Sider
        breakpoint="xl"
        width={219}
        collapsedWidth="0"
        // onBreakpoint={(broken) => {
        //   console.log({ broken });
        // }}
        // onCollapse={(collapsed, type) => {
        //   console.log({ collapsed, type });
        // }}
      >
        <div className="logo">
          <img src={logoSrc} alt="MenuPro" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          selectedKeys={selectedKeys}
          // onClick={({ key }) => {
          // navigate(`/${key}`)
          // }}
          className="main-layout-sider-menu"
          items={menItems}
        />
      </Sider>
      <Layout>
        <Content
        // style={{ margin: "24px 16px 0" }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
