import {
  AddResponse,
  GetListResponse,
  GetRecordResponse,
} from "../../global-types";
import axiosInstance, { apiRoutes } from "../config";
import { useTokenHook } from "../global-hooks";

import { User } from "../../global-types";
import { MenuItemTag } from "../menuTag/menuTag.srv";

export interface Restaurant {
  _id: string;
  status: "PENDING" | "BLOCKED" | "PUBLISHED";
  name: string;
  slogan: string;
  address: string;
  lat: number;
  lng: number;
  cover?: string;
  logo?: string;
  suggested_by: string | User;
  confirmed_by: string | User;
  tags: MenuItemTag[] | string[];
}

export const restaurantService = () => {
  const tokenHook = useTokenHook();

  return {
    ready: tokenHook.token?.length ? true : false,
    getRestaurants: (
      page: number,
      limit: number,
      searchQuery?: string
    ): Promise<GetListResponse<Restaurant>> => {
      return new Promise((resolve) => {
        axiosInstance
          .get(
            `${
              searchQuery && searchQuery !== ""
                ? apiRoutes.filterRestaurants
                : apiRoutes.restaurants
            }?page=${page}&limit=${limit}&query=${searchQuery}`
          )
          .then((response) => {
            resolve({
              success: true,
              data: response.data?.restaurants || response.data?.data || [],
              countAll: response.data.total,
            });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    addRestaurant: (data: Restaurant): Promise<AddResponse<Restaurant>> => {
      return new Promise((resolve) => {
        axiosInstance
          .post(`${apiRoutes.restaurants}`, data)
          .then((response) => {
            // console.log("==> RESP ADD RESTO", response);
            resolve({ success: true, record: response.data?._doc });
          })
          .catch((error) => resolve({ success: false, error }));
      });
    },
    getRestaurant: (
      restaurantId: string
    ): Promise<
      GetRecordResponse<{
        averagePrice?: number;
        averageRating?: number;
        restaurant: Restaurant;
      }>
    > => {
      return new Promise((resolve) => {
        axiosInstance
          .get(`${apiRoutes.restaurants}/${restaurantId}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
    editRestaurant: (
      id: string,
      data: Restaurant
    ): Promise<AddResponse<Restaurant>> => {
      return new Promise((resolve) => {
        axiosInstance
          .patch(`${apiRoutes.restaurants}/${id}`, data)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
    deleteRestaurant: (id: string): Promise<AddResponse<any>> => {
      return new Promise((resolve) => {
        axiosInstance
          .delete(`${apiRoutes.restaurants}/${id}`)
          .then((response) => resolve({ success: true, record: response.data }))
          .catch((error) => resolve({ success: false, error }));
      });
    },
  };
};
